import { Interface, getAddress } from 'ethers/lib/utils'
import FaucetToken from '../abi/faucet_token.json'
import Vault from '../abi/vault.json'
import IERC20 from '../abi/IERC20.json'
import NFT from '../abi/NFT.json'


import {
    signTransaction,
    signTransactionCallback,
    signTransactionWithRedirect,
} from '@joyid/evm'
import { ethers, utils, providers, BigNumber } from 'ethers'
import NETWORK from "../config/network"


import UseWallet from '../utils/wallet'

const { walletGlobal } = UseWallet()

export function provider() {
    return walletGlobal.provider
}

// mint
export const mintWithJoyId = async (contractAddress, account, amount) => {
    console.log('mint with joy id ', contractAddress, account, amount, ethers.utils.parseEther(amount))
    const tx = await signTransaction({
        to: contractAddress,
        from: account,
        data: buildMintData(account, ethers.utils.parseEther(amount)),
    })

    const txRes = await provider().sendTransaction(tx)
}

const buildMintData = (toAddress, amount) => {
    const iface = new Interface(FaucetToken.abi)
    const rawData = iface.encodeFunctionData('mint', [
        getAddress(toAddress),
        amount,
    ])

    console.log(rawData)
    return rawData
}

// approveJoyId
export const approveJoyId = async (contractAddress, spender, account, amount) => {
    const tx = await signTransaction({
        to: contractAddress,
        from: account,
        data: buildApproveData(spender, ethers.utils.parseEther(amount)),
    })

    const txRes = await provider().sendTransaction(tx)
}

const buildApproveData = (spender, amount) => {
    const iface = new Interface(IERC20)
    const rawData = iface.encodeFunctionData('approve', [
        spender,
        amount,
    ])
    return rawData
}

// depositJoyId
export const depositJoyId = async (contractAddress, token, account, amount) => {
    const tx = await signTransaction({
        to: contractAddress,
        from: account,
        data: buildDepositData(token, ethers.utils.parseEther(amount)),
    })

    const txRes = await provider().sendTransaction(tx)
}

const buildDepositData = (token, amount) => {
    const iface = new Interface(Vault)
    const rawData = iface.encodeFunctionData('deposit', [
        token,
        amount,
    ])
    return rawData
}

// withdrawJoyId
export const withdrawJoyId = async (contractAddress, token, account, amount) => {
    const tx = await signTransaction({
        to: contractAddress,
        from: account,
        data: buildWithdrawData(token, ethers.utils.parseEther(amount)),
    })

    const txRes = await provider().sendTransaction(tx)
}

const buildWithdrawData = (token, amount) => {
    const iface = new Interface(Vault)
    const rawData = iface.encodeFunctionData('deposit', [
        token,
        amount,
    ])
    return rawData
}

// mintNFTJoyId
export const mintNFTJoyId = async (contractAddress, account) => {
    const tx = await signTransaction({
        to: contractAddress,
        from: account,
        data: buildMintNFTData(),
        value: '0',
    })

    const txRes = await provider().sendTransaction(tx)
}

const buildMintNFTData = () => {
    const iface = new Interface(NFT)
    const rawData = iface.encodeFunctionData('mint', [])
    return rawData
}
// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
  try {
    const response = await fetch(`https://min-api.cryptocompare.com/${path}`)
    return response.json()
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`)
  }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`
  return {
    short,
    full: `${exchange}:${short}`,
  }
}

export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/)
  if (!match) {
    return null
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  }
}

export async function klines(symbol) {
  try {
    let ret = await fetch(`https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=1d`)
      .then((res) => res.json())
      .then((data) => {
        const cdata = data.map((d) => {
          return { time: d[0] / 1000, open: d[1], high: d[2], low: d[3], close: d[4] }
        })
        return cdata
      })
      .catch((err) => console.log(err))
    return ret
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`)
  }
}

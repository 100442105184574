<template>
  <div class="-ml-8 flex justify-items-center items-center">
    <button class="cursor-pointer px-4 py-1 rounded mr-4 text-gray-50 hover:text-white flex flex-row space-x-2" @click="onClick">
      <img src="../assets/ic_wallet_24.svg" alt="" />
      <span>{{ formatAddress(account) }}</span>
    </button>

    <WalletModal :showWallet="showConnect" @close="showConnect = false" />
    <WalletDisconnectModal :show="showDisconnect" @close="showDisconnect = false" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import WalletModal from './WalletModal.vue'
import WalletDisconnectModal from './WalletDisconnectModal.vue'

export default {
  name: 'ConnectWallet',
  data() {
    return {
      btnTxt: 'Connect Wallet',
      showConnect: false,
      showDisconnect: false,
    }
  },
  components: {
    WalletModal,
    WalletDisconnectModal,
  },
  computed: {
    ...mapGetters(['account']),
  },
  created() {},
  watch: {
    $route(to, from) {},
  },
  methods: {
    onClick() {
      if (this.account == 'Connect Wallet') {
        this.showConnect = true
      } else {
        this.showDisconnect = true
      }
    },
    formatAddress(address) {
      if (address.includes('Connect')) {
        return address
      }
      return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`
    },
    disConnect() {},
  },
}
</script>
<style scoped></style>

<template>
  <div class="w-[680px] mx-auto">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-5">
      <thead class="text-xs text-white uppercase bg-transparent border border-gray-700">
        <tr>
          <th scope="col" class="px-6 py-3">NFT ID</th>
          <th scope="col" class="px-6 py-3">Sender Blockchain</th>
          <th scope="col" class="px-6 py-3">Receiver Blockchain</th>
          <th scope="col" class="px-6 py-3">NDX Amount</th>
          <th scope="col" class="px-6 py-3">Operation</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in records" :key="index" class="border-b bg-slate-300 dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{{ item.NftTokenId }}</th>
          <td class="px-6 py-4">{{ item.SenderChain }}</td>
          <td class="px-6 py-4">{{ item.ReceiverChain }}</td>
          <td class="px-6 py-4">{{ item.TokenAmount }}</td>
          <td class="px-6 py-4"><button class="bg-blue-500 px-4 py-1" @click="redeem(item)">Redeem</button></td>
        </tr>
      </tbody>
    </table>

    <div v-if="noData" class="flex text-white h-20 justify-center items-center my-auto border mt-2 border-gray-700">
      <span> No data</span>
    </div>
  </div>
</template>

<script>
import { redeemNDX } from '../../contract/bridge'
import { checkRedeemAble } from '../../api/bridge'
import { mapActions, mapGetters } from 'vuex'
import NETWORK from '../../config/network'

export default {
  data() {
    return {
      timer: null,
      records: [],
    }
  },
  computed: {
    ...mapGetters(['account', 'chainId']),
    noData() {
      return this.records.length == 0
    },
  },
  methods: {
    async geRedeemAble() {
      try {
        let resp = await checkRedeemAble({ account: this.account })
        return resp.data
      } catch (error) {
        return []
      }
    },
    async tick() {
      this.records = await this.geRedeemAble()
    },
    async redeem(item) {
      console.log('redeem ndx', item.NftTokenId, item.ReceiverChain)
      if (this.chainId != item.ReceiverChain) {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [NETWORK[item.ReceiverChain]],
        })
      }
      await redeemNDX(item.NftTokenId)
    },
  },
  mounted() {
    this.timer = setInterval(this.tick, 2000)
  },
  beforeUnmount() {
    clearTimeout(this.timer)
  },
}
</script>

<style scoped></style>

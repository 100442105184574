<template>
  <div class="flex flex-row w-full h-full border-t border-black">
    <div class="h-full px-2 border-r border-black" style="width: 15%">
      <aside class="mt-2" aria-label="Sidebar">
        <div class="rounded bg-transparent">
          <ul class="space-y-2 w-full hover:bg-gray-800">
            <li class="flex justify-start w-[80%] items-center p-2 text-base font-normal text-gray-200">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-blue-500">
                <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875z" />
                <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 001.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 001.897 1.384C6.809 12.164 9.315 12.75 12 12.75z" />
                <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 15.914 9.315 16.5 12 16.5z" />
                <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 001.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 001.897 1.384C6.809 19.664 9.315 20.25 12 20.25z" />
              </svg>
              <span class="ml-3">Asset</span>
            </li>
          </ul>
        </div>
      </aside>
    </div>
    <div class="h-full" style="width: 85%">
      <div class="h-full">
        <div class="text-white text-2xl h-12 flex items-center ml-3 border-b border-black">Asset</div>
        <table class="w-full text-sm text-left text-gray-500 bg-gray-800">
          <thead class="text-xs uppercase bg-transparent">
            <tr class="w-full">
              <th class="py-3 px-6 w-[15%]">Asset</th>
              <th class="py-3 px-6 w-[15%]">Name</th>
              <th class="py-3 px-6 w-[10%]">Available</th>
              <th class="py-3 px-6 w-[10%]">Locked</th>
              <th class="py-3 px-6 w-[20%]">Address</th>
              <th class="py-3 px-6 w-[20%]">Operation</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(token, idx) in tokens()" :key="idx" class="border-b bg-gray-900 border-gray-700">
              <th scope="row" class="flex flex-row items-center py-3 px-6 font-medium text-gray-500 whitespace-nowrap">
                <img class="h-6 mr-2" :src="getTokenLogo(token.name)" alt="" />
                {{ token.name }}
              </th>
              <td class="py-3 px-6">
                {{ getTokenConf(token.name).info }}
              </td>
              <td class="py-3 px-6">
                {{ balances[token.name] == nil ? 0 : $format(balances[token.name], 4) }}
              </td>
              <td class="py-3 px-6">
                {{ lockedBalances[token.name] == nil ? 0 : $format(lockedBalances[token.name] / 10 ** 18, 4) }}
              </td>
              <td class="py-3 px-6">
                {{ getTokenConf(token.name).address }}
              </td>
              <td class="py-3 px-6">
                <router-link class="hover:underline" to="/trade"> Trade </router-link>
                <button v-show="!chain.Mainnet" class="ml-6 hover:underline" @click="onFaucet(token.name)">Faucet</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <TradeFooter class="absolute bottom-0 w-full" />
  </div>
</template>

<script>
import { getTokenList } from '../config/token_list'
import { getTokenCfg } from '../utils/token'
import { lockedBalance } from '../api'
import { mapGetters } from 'vuex'
import { getVaultBalance } from '../contract/contract'
import { mint } from '../contract/contract'
import { showToast } from '../utils/toast'
import { tokenLogos } from '../config'

export default {
  data() {
    return {
      balances: [],
      lockedBalances: [],
    }
  },
  computed: {
    ...mapGetters(['account', 'assetBalance', 'chainId', 'chain']),
  },
  watch: {
    async chainId() {
      await this.loadBalance()
    },
    async account() {
      await this.loadBalance()
    },
  },
  methods: {
    getTokenLogo(symbol) {
      return tokenLogos[symbol]
    },
    getTokenConf(symbol) {
      return getTokenCfg(symbol)
    },
    tokens() {
      const tokenList = getTokenList(this.chainId)
      return tokenList.map((token, index) => {
        return {
          name: token.symbol,
          id: index,
        }
      })
    },
    async loadBalance() {
      const tokenList = getTokenList(this.chainId)
      let list = tokenList.map((token, index) => {
        return {
          name: token.symbol,
          id: index,
        }
      })

      Promise.all(
        list.map(async (item) => {
          let balance = await getVaultBalance(item.name, this.account)
          this.balances[item.name] = balance
        })
      )
      this.lockedBalances = await lockedBalance({ address: this.account })
      console.log('lockedBalance', this.lockedBalances)
    },
    async onFaucet(symbol) {
      let tokenCfg = getTokenCfg(symbol)
      if (tokenCfg.faucetUrl != null) {
        window.open(tokenCfg.faucetUrl, '_blank')
      } else {
        let tokenAddress = tokenCfg.address
        await mint(tokenAddress, this.account, '10000')
        showToast('success', 'Token minted')
      }
    },
  },
  async mounted() {
    await this.loadBalance()
  },
}
</script>

<style></style>

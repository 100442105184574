<template>
  <div class="bg-gradient-to-b from-gray-900 to-gray-800 pt-10">
    <div class="container m-auto">
      <div class="grid grid-cols-2 gap-8 py-6 px-6 md:grid-cols-4">
        <div>
          <div class="w-[80px] md:ml-20 -mt-2" style="">
            <img src="../../assets/logo.png" />
          </div>
          <span class="text-gray-400 w-30 flex-wrap"
            >A decentralized exchange platform
            <br />
            built on EVM blockchains.
          </span>
        </div>
        <div>
          <h2 class="mb-6 text-sm font-semibold text-gray-400 uppercase">PRODUCTS</h2>
          <ul class="text-gray-300">
            <li class="mb-4">
              <a href="https://docs.nevdex.tech/products/exchange" target="_blank" class="hover:underline">Exchange</a>
            </li>
            <li class="mb-4">
              <a href="javascript:void(0)" class="hover:underline">Farm</a>
            </li>
            <li class="mb-4">
              <a href="javascript:void(0)" class="hover:underline">NFT</a>
            </li>
          </ul>
        </div>
        <div>
          <h2 class="mb-6 text-sm font-semibold text-gray-400 uppercase">RESOURCES</h2>
          <ul class="text-gray-300">
            <li class="mb-4">
              <a href="https://docs.nevdex.tech/" target="_blank" class="hover:underline">Docs</a>
            </li>
            <li class="mb-4">
              <a href="https://github.com/NevDEX" target="_blank" class="hover:underline">GitHub</a>
            </li>
          </ul>
        </div>
        <div>
          <h2 class="mb-6 text-sm font-semibold text-gray-400 uppercase">FOLLOW US</h2>
          <ul class="text-gray-300">
            <li class="mb-4 flex flex-row space-x-2 items-center">
              <svg class="w-5 h-5 text-gray-600" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                />
              </svg>
              <a href="https://twitter.com/NevDEX_" target="_blank" class="hover:underline">Twitter</a>
            </li>
            <li class="mb-4 flex flex-row space-x-2 items-center">
              <svg class="w-5 h-5 text-gray-600" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path
                  fill="currentColor"
                  d="M524.5 69.84a1.5 1.5 0 0 0 -.764-.7A485.1 485.1 0 0 0 404.1 32.03a1.816 1.816 0 0 0 -1.923 .91 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.14-30.6 1.89 1.89 0 0 0 -1.924-.91A483.7 483.7 0 0 0 116.1 69.14a1.712 1.712 0 0 0 -.788 .676C39.07 183.7 18.19 294.7 28.43 404.4a2.016 2.016 0 0 0 .765 1.375A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.063-.676A348.2 348.2 0 0 0 208.1 430.4a1.86 1.86 0 0 0 -1.019-2.588 321.2 321.2 0 0 1 -45.87-21.85 1.885 1.885 0 0 1 -.185-3.126c3.082-2.309 6.166-4.711 9.109-7.137a1.819 1.819 0 0 1 1.9-.256c96.23 43.92 200.4 43.92 295.5 0a1.812 1.812 0 0 1 1.924 .233c2.944 2.426 6.027 4.851 9.132 7.16a1.884 1.884 0 0 1 -.162 3.126 301.4 301.4 0 0 1 -45.89 21.83 1.875 1.875 0 0 0 -1 2.611 391.1 391.1 0 0 0 30.01 48.81 1.864 1.864 0 0 0 2.063 .7A486 486 0 0 0 610.7 405.7a1.882 1.882 0 0 0 .765-1.352C623.7 277.6 590.9 167.5 524.5 69.84zM222.5 337.6c-28.97 0-52.84-26.59-52.84-59.24S193.1 219.1 222.5 219.1c29.67 0 53.31 26.82 52.84 59.24C275.3 310.1 251.9 337.6 222.5 337.6zm195.4 0c-28.97 0-52.84-26.59-52.84-59.24S388.4 219.1 417.9 219.1c29.67 0 53.31 26.82 52.84 59.24C470.7 310.1 447.5 337.6 417.9 337.6z"
                ></path>
              </svg>
              <a href="https://discord.gg/U5S86gJePS" target="_blank" class="hover:underline">Discord</a>
            </li>
            <li class="mb-4 flex flex-row space-x-2 items-center">
              <svg class="w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)">
                <path fill="currentColor" d="M136,128c0,37.5-28.7,68-64,68S8,165.5,8,128,36.7,60,72,60,136,90.5,136,128Zm48-64c-5.7,0-16.4,2.8-24.3,21.3-5,11.5-7.7,26.7-7.7,42.7s2.7,31.2,7.7,42.7c7.9,18.5,18.6,21.3,24.3,21.3s16.4-2.8,24.3-21.3c5-11.5,7.7-26.7,7.7-42.7s-2.7-31.2-7.7-42.7C200.4,66.8,189.7,64,184,64Zm56,0a8,8,0,0,0-8,8V184a8,8,0,0,0,16,0V72A8,8,0,0,0,240,64Z"></path>
              </svg>

              <a href="https://medium.com/@NevDEX" target="_blank" class="hover:underline">Medium</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="py-4 px-4 bg-transparent md:flex md:items-center md:justify-center">
        <span class="text-sm text-gray-500 sm:text-center">© {{ this.year }} <a href="#">NevDEX™</a>. All Rights Reserved. </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: '',
    }
  },
  mounted() {
    this.year = new Date().getFullYear()
  },
}
</script>

<style></style>

import { ethers } from 'ethers'
import abiNFT from '../abi/NFT.json'
import UseWallet from '../utils/wallet'
import abiERC20 from '../abi/IERC20.json'
import abiBridge from '../abi/IERC20.json'
import abiGuestBridge from '../abi/GuestBridge.json'
import { getNDXAddress, getBridgeAddress, getNFTAddress, getChainId } from '../config'

// approve bridge NDX amount
async function approve(amount) {
  const { walletGlobal } = UseWallet()
  if (walletGlobal.wallet == 'joy.id') {
    await approveJoyId(tokenAddress, getBridgeAddress(), walletGlobal.account, ethers.utils.parseEther(amount.toString()))
  } else {
    let signer = walletGlobal.signer
    const tokenContract = new ethers.Contract(getNDXAddress(), abiERC20, signer)
    let tx = await tokenContract.approve(getBridgeAddress(), ethers.utils.parseEther(amount.toString()))
    await tx.wait()
  }
}

async function sendNFT(account, tokenId, chainId, toChainId) {
  const { walletGlobal } = UseWallet()
  const signer = walletGlobal.signer
  const nftContract = new ethers.Contract(getNFTAddress(), abiNFT, signer)
  const deadline = Math.round(Date.now() / 1000 + 5 * 60 * 60)
  const toChainIdBytes = ethers.utils.arrayify(toChainId)
  const nonce = await nftContract.nonces(0)
  const signature = await sign(signer, account, tokenId, nonce, deadline, nftContract, chainId)

  await nftContract.safeTransferFromWithPermit(account, getBridgeAddress(), tokenId, toChainIdBytes, deadline, signature)
}

async function sign(deployer, spender, tokenId, nonce, deadline, nft, chainId) {
  const typedData = {
    types: {
      Permit: [
        { name: 'spender', type: 'address' },
        { name: 'tokenId', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
      ],
    },
    primaryType: 'Permit',
    domain: {
      name: await nft.name(),
      version: '1',
      chainId: chainId,
      verifyingContract: nft.address,
    },
    message: {
      spender,
      tokenId,
      nonce,
      deadline,
    },
  }

  // sign Permit
  const signature = await deployer._signTypedData(typedData.domain, { Permit: typedData.types.Permit }, typedData.message)

  return signature
}

function getBridgeContract() {
  const address = getBridgeAddress()
  console.log('getBridgeContract', address)
  const { walletGlobal } = UseWallet()
  const signer = walletGlobal.signer

  let chainId = getChainId()
  const bridgeContract = chainId == 71401 || chainId == 71402 ? new ethers.Contract(address, abiBridge, signer) : new ethers.Contract(address, abiGuestBridge, signer)

  return bridgeContract
}

async function redeemNDX(nftTokenId, receiverChainId) {
  const bridgeContract = getBridgeContract()
  let tx = await bridgeContract.redeem(nftTokenId)
  await tx.wait()
}

export { approve, sendNFT, sign, redeemNDX }

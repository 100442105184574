<template>
  <div class="flex flex-row items-center w-full bg-gray-800">
    <Popover v-slot="{ open }" class="relative w-full">
      <PopoverButton @click="onClick" class="w-full justify-center group inline-flex items-center py-2 text-base font-medium hover:text-white outline-none">
        <span class="flex flex-row space-x-3">
          <div v-if="selectedItem != null">
            <img class="h-[1.5rem]" :src="`${selectedItem.img}`" alt="logo" />
          </div>
          <div v-else>Select NFT</div>

          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#98A1C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mt-0.5 ml-2">
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </span>
        <ChevronDownIcon :class="open ? ' ' : ' '" class="ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80" aria-hidden="true" />
      </PopoverButton>

      <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in" leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
        <PopoverPanel v-slot="{ close }" class="absolute left-1/2 z-10 mt-3 -translate-x-1/2 transform sm:px-0 w-full">
          <div class="overflow-hidden shadow-lg bg-slate-800 w-full">
            <div class="w-ful">
              <li v-if="nfts.length == 0" class="h-20 flex items-center justify-center">No data</li>

              <li v-for="(item, index) in nfts" :key="index" @click="accept(close, item)">
                <a href="#" class="flex items-center justify-center py-1 text-base font-bold text-gray-900 hover:bg-gray-600 group hover:shadow px-60">
                  <img class="h-[1.5rem]" :src="`${item.img}`" alt="logo" />
                  <!-- <span class="flex-1 ml-3 whitespace-nowrap text-white">{{ item.tokenId }}</span> -->
                </a>
              </li>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { getSupportedChain } from '../../config'
import store from '../../store'
import { ethers } from 'ethers'
import { getOwnedTokens } from '../../contract/nft'

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  data() {
    return {
      correctNetwork: true,
      switchNetwork: false,
      nfts: [],
      selectedItem: null,
    }
  },
  computed: {
    ...mapGetters(['account', 'chain', 'chainId']),
  },
  methods: {
    async accept(close, item) {
      this.selectedItem = item
      store.commit('updateImportNftTokenId', item.tokenId)
      close()
    },
    async onClick() {
      let nfts = await getOwnedTokens(this.account)
      let tokenIds = nfts.map((n) => {
        const tokenId = n.toString()
        const url = `https://gateway.pinata.cloud/ipfs/QmSqNC8G6r1vd8rnw9bRnsLSuyKp3jTJq71cFDhHNzVaCH/${tokenId}.avif`
        return { tokenId, img: `https://gateway.pinata.cloud/ipfs/QmSqNC8G6r1vd8rnw9bRnsLSuyKp3jTJq71cFDhHNzVaCH/${tokenId}.avif` }
      })
      this.nfts = tokenIds
    },
  },
  mounted: async function () {},
}
</script>

<style></style>

<template>
  <div class="md:mt-0 item-center shadow md:flex md:items-center md:justify-between md:p-1 bg-gray-800 w-full">
    <ul class="flex flex-wrap items-center text-sm text-gray-500 m-auto justify-center">
      <li>
        <a href="https://twitter.com/NevDEX_" target="_blank" class="mr-4 hover:underline md:mr-6">Twitter</a>
      </li>
      <li>
        <a href="https://discord.gg/U5S86gJePS" target="_blank" class="mr-4 hover:underline md:mr-6">Discord</a>
      </li>
      <li>
        <a href="https://github.com/NevDEX" target="_blank" class="mr-4 hover:underline md:mr-6">GitHub </a>
      </li>
      <!-- <li>
        <a href="https://www.nervos.org/" target="_blank" class="mr-4 hover:underline md:mr-6">Nervos Network </a>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>

<template>
  <li @click="onConnect">
    <a href="#" class="flex items-center justify-start p-3 text-base font-bold text-gray-900 bg-gray-600 rounded hover:bg-gray-100 group hover:shadow">
      <div class="h-6">
        <img class="h-6" :src="logo" alt="" />
      </div>
      <span class="flex-1 ml-3 whitespace-nowrap">Smart Wallet</span>
    </a>
  </li>
</template>
<script>
import store from '../../store'
import UseWallet from '../../utils/wallet'
const { walletGlobal } = UseWallet()
import coinbaseLogo from '../../assets/coinbase.png'
import { mapGetters } from 'vuex'
import { client, baseSepolia } from '../../utils/coinbase'
import { signLoginPayload } from 'thirdweb/auth'
import { sdk, wallet } from '../../utils/coinbase'
import { ethers } from 'ethers'
import FaucetToken from '../../abi/faucet_token.json'
export default {
  components: {},
  data() {
    return {
      logo: coinbaseLogo,
    }
  },
  methods: {
    async onConnect() {
      try {
        const web3Provider = sdk.makeWeb3Provider({ options: 'smartWalletOnly' })
        const addresses = await web3Provider.request({ method: 'eth_requestAccounts' })
        const address = addresses[0]
        const provider = new ethers.providers.Web3Provider(web3Provider)
        const signer = provider.getSigner()

        // *** sign message***
        // let messageHashBytes = ethers.utils.arrayify('0x1233')
        // console.log('messageHashBytes', messageHashBytes)
        // let signature1 = await signer.signMessage(messageHashBytes)
        // console.log('signature', signature1)

        // *** send tx ***
        // const tokenContract = new ethers.Contract('0x22c0DB4CC9B339E34956A5699E5E95dC0E00c800', FaucetToken.abi, signer)
        // await tokenContract.mint(address, ethers.utils.parseEther('1'))
        // return

        // *** thiridweb ***
        // const account = await wallet.connect({ client })
        // const chain = baseSepolia
        // wallet.switchChain(chain)
        // const address = account.address

        store.commit('setAccount', address)
        // walletGlobal.wallet = 'smartWallet'
        walletGlobal.account = address
        walletGlobal.signer = signer
        this.$emit('close')
        return
        
        // *** thiridweb auth *** 
        const payload = {
          domain: 'string',
          address: 'string',
          statement: 'string',
          // uri?: string,
          version: 'string',
          // chain_id?: string,
          nonce: 'string',
          issued_at: 'string',
          expiration_time: 'string',
          invalid_before: 'string',
          // resources?: string[],
        }
        const signature = await signLoginPayload({ payload, account })

        this.$emit('close')
      } catch (error) {
        console.log(error)
      }
    },
  },
  computed: {
    ...mapGetters(['account', 'chainId', 'chain']),
  },
  mounted() {},
}
</script>
<style></style>

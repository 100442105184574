<template>
  <div class="w-full text-center rounded-lg shadow-md sm:p-8 mx-auto h-full bg-image">
    <h5 class="text-center mb-2 text-3xl font-bold text-white mr-10">Send the NDX token with the Bull Nation NFT</h5>
    <div class="w-[680px] items-center mx-auto justify-between mt-8 text-white">
      <div class="border border-gray-600 w-full py-1.5">
        <button class="w-1/3" @click="onTab('transfer')"><span v-bind:class="[tab === 'transfer' ? 'border-b border-blue-600 py-0.5' : '']">Transfer</span></button>
        <button class="w-1/3" @click="onTab('redeem')"><span v-bind:class="[tab === 'redeem' ? 'border-b border-blue-600 py-0.5' : '']">Redeem</span></button>
        <button class="w-1/3" @click="onTab('history')"><span v-bind:class="[tab === 'history' ? 'border-b border-blue-600 py-0.5' : '']">History</span></button>
      </div>
    </div>
    <Transfer v-bind:class="[tab === 'transfer' ? 'block' : 'hidden']"></Transfer>
    <Redeem v-bind:class="[tab === 'redeem' ? 'block' : 'hidden']"></Redeem>
    <History v-bind:class="[tab === 'history' ? 'block' : 'hidden']"></History>
  </div>
  <!-- <TradeFooter class="w-full" /> -->
</template>
<script>
import Footer from '../components/footer/Footer.vue'
import { mapGetters } from 'vuex'
import { approve, sendNFT, redeemNDX } from '../contract/bridge'
import { mint, getOwnedTokens } from '../contract/nft'
import NFTDropdown from '../components/bridge/NFTDropdown.vue'
import ReceiverBlockchain from '../components/bridge/ReceiverBlockchain.vue'
import Transfer from '../components/bridge/BridgeTransfer.vue'
import Redeem from '../components/bridge/BridgeRedeem.vue'
import History from '../components/bridge/BridgeHistory.vue'
import TradeFooter from '../components/footer/TradeFooter.vue'

export default {
  data() {
    return {
      tab: 'transfer',
    }
  },
  components: {
    Footer,
    ReceiverBlockchain,
    NFTDropdown,
    Transfer,
    Redeem,
    History,
  },
  computed: {
    ...mapGetters(['account', 'chainId']),
  },
  methods: {
    async mintNFT() {
      await mint(this.account)
    },
    async redeem(nftTokenId) {
      await redeemNDX(nftTokenId)
    },
    onTab(name) {
      this.tab = name
    },
  },
}
</script>
<style scoped>
.bg-image {
  background-image: url('../assets/bridge/grid.png');
}
</style>

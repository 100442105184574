import axios from 'axios'
import { showToast } from '../toast'

// create an axios instance
const service = axios.create({
    baseURL: 'https://basesepolia.nevdex.tech/api/',
    timeout: 500000, // request timeout
})

// request interceptor
service.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code !== 20000) {
            console.error('http return ', res)
            showToast('danger', res.desc)
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res.data
        }
    },
    (error) => {
        console.error('response err' + error) // for debug
        return Promise.reject(error)
    }
)

export default service

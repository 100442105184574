<template>
  <div class="w-[680px] mx-auto">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-5">
      <thead class="text-xs text-white uppercase bg-gray-50 border border-gray-700 bg-transparent">
        <tr>
          <th scope="col" class="px-6 py-3">NFT ID</th>
          <th scope="col" class="px-6 py-3">Sender Blockchain</th>
          <th scope="col" class="px-6 py-3">Receiver Blockchain</th>
          <th scope="col" class="px-6 py-3">NDX Amount</th>
          <th scope="col" class="px-6 py-3">Time</th>
        </tr>
      </thead>

      <tbody class="text-center bg-gray-600">
        <tr v-for="(item, index) in records" :key="index" class="border-b bg-slate-300 dark:bg-gray-800 dark:border-gray-700">
          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{{ item.NftTokenId }}</th>
          <td class="px-6 py-4">{{ item.SenderChain }}</td>
          <td class="px-6 py-4">{{ item.ReceiverChain }}</td>
          <td class="px-6 py-4">{{ item.TokenAmount }}</td>
          <!-- <td class="px-6 py-4"><button class="bg-blue-500 px-4 py-1">Redeem</button></td> -->
        </tr>
      </tbody>
    </table>
    <div v-if="noData" class="flex text-white h-20 justify-center items-center my-auto border mt-2 border-gray-700">
      <span> No data</span>
    </div>
  </div>
</template>

<script>
import { history } from '../../api/bridge'

export default {
  data() {
    return {
      timer: null,
      records: [],
    }
  },
  computed: {
    noData() {
      return this.records == 0
    },
  },
  methods: {
    async getBridgeRecord() {
      try {
        let resp = await history({ account: this.account })
        return resp.data
      } catch (error) {
        return []
      }
    },
    async tick() {
      this.data = await this.getBridgeRecord()
    },
  },
  mounted() {
    this.timer = setInterval(this.tick, 2000)
  },
  beforeUnmount() {
    clearTimeout(this.timer)
  },
}
</script>

<style scoped></style>

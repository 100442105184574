<template>
  <div class="flex flex-col px-2">
    <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-600">
      <ul class="flex -mb-px">
        <li class="mr-2">
          <a href="javascript:void(0)" :class="[openTab === 1 ? ' text-bold text-gray-300 border-blue-600  ' : 'border-transparent ', 'inline-block p-2 rounded-t-lg border-b-2 hover:text-gray-300 hover:border-blue-600 ']" @click="toggleTabs(1)">Open Orders</a>
        </li>
        <li class="mr-2">
          <a href="javascript:void(0)" :class="[openTab === 2 ? ' text-bold text-gray-300 border-blue-600  ' : 'border-transparent ', 'inline-block p-2 rounded-t-lg border-b-2 hover:text-gray-300 hover:border-blue-600 ']" @click="toggleTabs(2)">Recent Trade History</a>
        </li>
      </ul>
    </div>
    <div class="flex flex-col min-w-0 break-words w-full h-full" style="">
      <div class="tab-content tab-space h-full">
        <div v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }" class="h-full">
          <!-- OpenOrder Start  -->
          <div class="flex flex-col w-full text-left h-full">
            <div class="flex flex-shrink-0 text-gray-400 uppercase text-xs py-2">
              <div class="flex items-center flex-grow w-0 h-6"><span>Market</span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Side</span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Size</span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Filled </span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Price </span></div>
              <div class="flex items-center flex-grow w-0 h-6 pl-5"><span>Status </span></div>
              <div class="flex items-center flex-grow w-0 h-6 px-5"><span>Create Time</span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Operation </span></div>
            </div>
            <div class="overflow-auto flex-1 bg-transparent">
              <div v-if="openOrders.length == 0" class="text-gray-400 items-center text-center mt-5 text-xs">No Open Orders</div>
              <div v-for="order in openOrders" :key="order.Id" class="flex flex-shrink-0 text-xs text-gray-100 py-5">
                <div class="flex items-center flex-grow w-0 h-6">
                  <span>{{ order.MarketId }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span class="capitalize">{{ order.Side }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span class="break-all">{{ order.Amount }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span class="break-all">{{ order.ConfirmedAmount }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span class="break-all">{{ order.Price }} {{ `${order.MarketId.split('-')[1]}` }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6 pl-5">
                  <span class="break-all capitalize">{{ order.Status }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6 px-5">
                  <span class="">{{ order.CreatedAt }}</span>
                </div>
                <div v-if="checkDisplayCancel(order)" class="flex items-center flex-grow w-0 h-6"><button class="font-medium text-xs text-blue-600 hover:text-blue-200" @click="onCancelOrder(order)">Cancel</button></div>
                <div v-else class="flex items-center flex-grow w-0 h-6"><button class="font-medium text-xs text-blue-600 hover:text-blue-200"></button></div>
              </div>
            </div>
          </div>
          <!-- OpenOrder End  -->
        </div>
        <div v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }" class="h-full">
          <!-- Recent History Start  -->
          <div class="flex flex-col w-full text-left h-full">
            <div class="flex flex-shrink-0 text-gray-400 uppercase text-xs py-2">
              <div class="flex items-center flex-grow w-0 h-6"><span>Market</span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Side</span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Size</span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Role </span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Price </span></div>
              <div class="flex items-center flex-grow w-0 h-6"><span>Fee </span></div>
              <div class="flex items-center flex-grow w-0 h-6 px-1"><span>Time</span></div>
              <div class="flex items-center flex-grow w-0 h-6 px-1"><span>TX</span></div>
            </div>
            <div class="overflow-auto h-50">
              <div v-if="tradeOrders.length == 0" class="text-gray-400 items-center text-center mt-5 text-xs">No Trade History</div>
              <div v-for="order in tradeOrders" :key="order.Id" class="flex flex-shrink-0 text-xs text-gray-100 py-5">
                <div class="flex items-center flex-grow w-0 h-6">
                  <span>{{ order.MarketId }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span class="capitalize" v-if="order.Taker == account"> {{ order.TakerSide }} </span>
                  <span class="capitalize" v-else>{{ order.TakerSide == 'buy' ? 'sell' : 'buy' }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span>{{ $format(order.Amount, 4) }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span v-if="order.Maker == account">Maker</span>
                  <span v-else>Taker</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span>{{ $format(order.Price, 4) }} {{ `${order.MarketId.split('-')[1]}` }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-6">
                  <span>{{ $format(tradeFee(order), 4) }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-5">
                  <span class="px-1">{{ dateTime(order.CreateTime) }}</span>
                </div>
                <div class="flex items-center flex-grow w-0 h-5">
                  <a :href="txLink(order.TransactionHash)" target="_blank" class="hover:underline">{{ shortTx(order.TransactionHash) }}</a>
                </div>
              </div>
            </div>
          </div>
          <!-- Recent History End  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { myOrders, cancelOrder } from '../api'
import { mapGetters, mapActions } from 'vuex'
import { showToast } from '../utils/toast'
import moment from 'moment'
import NETWORK from '../config/network'

export default {
  name: 'UserTrade',
  data() {
    return {
      openTab: 1,
      openOrders: [],
      tradeOrders: [],
      cancelDisplay: 'Cancel',
      feeTier: [
        {
          id: 1,
          maker: 0,
          taker: 0.22,
          balance: 0,
        },
        {
          id: 2,
          maker: 0,
          taker: 0.2,
          balance: 1000,
        },
        {
          id: 3,
          maker: 0,
          taker: 0.18,
          balance: 10000,
        },
        {
          id: 4,
          maker: 0,
          taker: 0.16,
          balance: 100000,
        },
        {
          id: 5,
          maker: 0,
          taker: 0.14,
          balance: 1000000,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['account', 'market', 'chainId']),
  },
  methods: {
    ...mapActions(['updateMarketVolume']),
    toggleTabs(tabNumber) {
      this.openTab = tabNumber
    },
    tick() {
      myOrders({
        address: this.account,
        marketID: this.market,
      })
        .then((ret) => {
          this.openOrders = ret.openOrder.filter((order) => {
            return order.Status != 'full_filled'
          })

          this.tradeOrders = ret.tradeOrders
          this.updateMarketVolume(ret.marketVolume)
        })
        .catch((e) => console.error(e))
    },
    onCancelOrder(order) {
      let { Id, MarketId } = order
      try {
        cancelOrder({
          marketID: MarketId,
          orderID: Id,
        })

        showToast('success', 'Order canceled')
      } catch (error) {}
    },
    dateTime(timestamp) {
      let offset = new Date() + ''
      let timezone = offset.indexOf('GMT')
      let timezoneId = offset.substring(timezone + 3, timezone + 8)
      let orderDate = moment(timestamp * 1000)
        .utcOffset(timezoneId)
        .format('YYYY-MM-DD HH:mm:ss')
      return orderDate
    },
    checkDisplayCancel(order) {
      return order.Status === 'pending'
    },
    tradeFee(order) {
      return order.Maker == this.account ? 0 : Number(order.Amount) * Number(order.Price) * Number(order.Fee)
    },
    shortTx(hash) {
      return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`
    },
    txLink(hash) {
      return `${NETWORK[this.chainId].blockExplorerUrls[0]}/tx/${hash}`
    },
  },
  mounted() {
    this.timer = setInterval(this.tick, 2000)
  },
  beforeUnmount() {
    clearTimeout(this.timer)
  },
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* background-color: transparent; */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}

.fixTableHead {
  overflow-y: auto;
  height: 110px;
}

.fixTableHead thead th {
  position: sticky;
  top: 0;
  background-color: black;
}

.trade-list {
  height: 35vh;
}
</style>

import { ethers, utils } from 'ethers'
import { getPermitSignature } from '../utils/sign'
import FaucetToken from '../abi/faucet_token.json'
import { getTokenCfg } from '../utils/token'
import UseWallet from '../utils/wallet'
import { mintWithJoyId, approveJoyId, depositJoyId } from "../utils/joyid"
import { getVaultAddress } from '../config'
import abiERC20 from '../abi/IERC20.json'
import abiVault from '../abi/vault.json'

import {
  getBalance as baseGetBalance,
  getVaultBalance as baseGetVaultBalance,
  mint as baseMint,
  allowance as baseAllowance,
  approve as baseApprove,
  deposit as baseDeposit,
} from "./contract_thirdweb"

async function approve(tokenAddress, amount, decimals) {
  // console.log('approve', tokenAddress, amount, ethers.utils.parseUnits(amount, decimals))
  const { walletGlobal } = UseWallet()
  if (walletGlobal.wallet == 'joy.id') {
    await approveJoyId(tokenAddress, getVaultAddress(), walletGlobal.account, amount)
  } else if (walletGlobal.wallet == 'smartWallet') {
    await baseApprove(tokenAddress, getVaultAddress(), amount)
  } else {
    let signer = walletGlobal.signer
    const tokenContract = new ethers.Contract(tokenAddress, abiERC20, signer)
    let tx = await tokenContract.approve(getVaultAddress(), ethers.utils.parseUnits(amount, decimals))
    await tx.wait()
  }
}

async function getBalance(symbol, account) {
  // console.log("getBalance: symbol:", symbol, account)
  const { walletGlobal } = UseWallet()
  let cfg = getTokenCfg(symbol)
  // console.log(cfg)

  if (walletGlobal.wallet == 'smartWallet') {
    let amount = await baseGetBalance(cfg.address, account)
    return ethers.utils.formatUnits(amount, cfg.decimals)
  }

  let signer = walletGlobal.signer
  if (walletGlobal.wallet == 'joy.id') {
    signer = walletGlobal.provider
  }

  const tokenContract = new ethers.Contract(cfg.address, abiERC20, signer)
  let amount = await tokenContract.balanceOf(account)
  // console.log("getBalance: symbol:", symbol, cfg.address, "account:", account, 'amount:', amount.toString(), ethers.utils.formatUnits(amount, cfg.decimals))
  return ethers.utils.formatUnits(amount, cfg.decimals)
}

async function getVaultBalance(symbol, account) {
  const { walletGlobal } = UseWallet()
  // console.log("getVaultBalance: symbol:", symbol, "account:", account)
  let cfg = getTokenCfg(symbol)

  if (walletGlobal.wallet == 'smartWallet') {
    let amount = await baseGetVaultBalance(getVaultAddress(), cfg.address, account)
    return ethers.utils.formatUnits(amount, cfg.decimals)
  }

  let signer = walletGlobal.signer
  if (walletGlobal.wallet == 'joy.id') {
    signer = walletGlobal.provider
  }
  const vaultContract = new ethers.Contract(getVaultAddress(), abiVault, signer)
  let amount = await vaultContract.balances(cfg.address, account)
  // console.log("getVaultBalance: symbol:", symbol, cfg.address, "account:", account, 'amount:', amount.toString(), ethers.utils.formatUnits(amount, cfg.decimals))
  return ethers.utils.formatUnits(amount.toString(), cfg.decimals)
}

async function deposit(symbol, amount) {
  let cfg = getTokenCfg(symbol)
  const { walletGlobal } = UseWallet()
  if (walletGlobal.wallet == "joy.id") {
    await depositJoyId(getVaultAddress(), cfg.address, walletGlobal.account, amount)
  } if (walletGlobal.wallet == "smartWallet") {
    await baseDeposit(cfg.address, getVaultAddress(), amount)
  } else {
    let signer = walletGlobal.signer
    // console.log('deposit', getVaultAddress(), signer)
    const vaultContract = new ethers.Contract(getVaultAddress(), abiVault, signer)
    let tx = await vaultContract.deposit(cfg.address, ethers.utils.parseUnits(amount, cfg.decimals))
    await tx.wait()
  }
}

// async function onAttemptToApprove(amount, erc20Address, deadline, account) {
//   // console.log('onAttemptToApprove', amount, erc20Address)
//   const { walletGlobal } = UseWallet()
//   let signer = walletGlobal.signer

//   const token = new ethers.Contract(erc20Address, FaucetToken.abi, signer)
//   const vaultContract = new ethers.Contract(getVaultAddress(), abiVault, signer)
//   amount = ethers.utils.parseEther(amount)
//   const { v, r, s } = await getPermitSignature(signer, account, token, vaultContract.address, amount, deadline)
//   // console.log("depositWithPermit:", amount, deadline, v, r, s)
//   let tx = await vaultContract.depositWithPermit(token.address, amount, deadline, v, r, s)
//   await tx.wait()
// }

async function withdraw(symbol, amount) {
  let cfg = getTokenCfg(symbol)
  const { walletGlobal } = UseWallet()
  if (walletGlobal.wallet == "joy.id") {

  } else {
    let signer = walletGlobal.signer
    const vaultContract = new ethers.Contract(getVaultAddress(), abiVault, signer)
    let tx = await vaultContract.withdraw(cfg.address, ethers.utils.parseUnits(amount, cfg.decimals))
    await tx.wait()
  }
}

async function allowance(tokenAddress, owner, decimals) {
  const { walletGlobal } = UseWallet()
  if (walletGlobal.wallet == 'smartWallet') {
    let val = await baseAllowance(tokenAddress, owner, getVaultAddress())
    // console.log('allowance', val, utils.formatUnits(val, decimals))
    return ethers.utils.formatUnits(val, decimals)
  } else {
    let signer = walletGlobal.signer
    const tokenContract = new ethers.Contract(tokenAddress, abiERC20, signer)
    return utils.formatUnits(await tokenContract.allowance(owner, getVaultAddress()), decimals)
  }
}

async function mint(tokenAddress, account, amount) {
  const { walletGlobal } = UseWallet()
  if (walletGlobal.wallet == "joy.id") {
    await mintWithJoyId(tokenAddress, account, amount)
  } else if (walletGlobal.wallet == 'smartWallet') {
    await baseMint(tokenAddress, account, amount)
  } else {
    let signer = walletGlobal.signer
    // console.log('mint tokenAddress', tokenAddress, account, amount)
    const tokenContract = new ethers.Contract(tokenAddress, FaucetToken.abi, signer)
    await tokenContract.mint(account, ethers.utils.parseUnits(amount, 18))
  }
}

export { mint, approve, getBalance, deposit, getVaultBalance, withdraw, allowance }

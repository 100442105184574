import axios from 'axios';
// import { BASE_URI } from '../constants';
const BASE_URI  = "http://127.0.0.1:7000/api"
const request = axios.create({
  baseURL: BASE_URI, timeout: 30000,
});

export function checkRedeemAble(data) {
    return request({
        url: '/bridge/checkRedeemAble',
        method: 'post',
        data
    })
}

export function history(data) {
  return request({
      url: '/bridge/record',
      method: 'post',
      data
  })
}
import request_godwoken_testnet from '../utils/axios/axios_godwoken_testnet'
import request_godwoken_mainnet from '../utils/axios/axios_godwoken_mainnet'
import request_fantom_testnet from '../utils/axios/axios_fantom_testnet'
import request_base_sepolia from '../utils/axios/axios_base_sepolia'
import request_polygon_mumbai from '../utils/axios/axios_polygon_mumbai'
import request_avalanche_fuji from '../utils/axios/axios_avalanche_fuji'
import axios_mode_testnet from '../utils/axios/axios_mode_testnet'
import axios_anvil_testnet from '../utils/axios/anvil_testnet'
import axios_base from '../utils/axios/axios_base'

const GODWOKEN_TESTNET = 71401
const GODWOKEN_MAINNET = 71402
const FANTOM_TESTNET = 4002
const BASE_SEPOLIA = 84532
const BASE = 8453
const POLYGON_MUMBAI = 80001
const AVALANCHE_FUJI = 43113
const MODE_TESTNET = 919
const ANVIL_TESTNET = 31337

import store from "../store/index"

function getRequestInterface() {
    const chainId = store.getters.chainId
    switch (chainId) {
        case GODWOKEN_TESTNET:
            return request_godwoken_testnet
        case GODWOKEN_MAINNET:
            return request_godwoken_mainnet
        case FANTOM_TESTNET:
            return request_fantom_testnet
        case BASE_SEPOLIA:
            return request_base_sepolia
        case POLYGON_MUMBAI:
            return request_polygon_mumbai
        case AVALANCHE_FUJI:
            return request_avalanche_fuji
        case MODE_TESTNET:
            return axios_mode_testnet
        case ANVIL_TESTNET:
            return axios_anvil_testnet
        case BASE:
            return axios_base
    }
    console.log('request interface not found chainId', chainId)
}

export function buildOrder(data) {
    let request = getRequestInterface()
    return request({
        url: '/order/build',
        method: 'post',
        data
    })
}

export function placeOrder(data) {
    let request = getRequestInterface()
    return request({
        url: '/order/place',
        method: 'post',
        data
    })
}

export function cancelOrder(data) {
    let request = getRequestInterface()
    return request({
        url: '/order/cancel',
        method: 'post',
        data
    })
}

export function listOrder(data) {
    let request = getRequestInterface()
    return request({
        url: '/order/orders',
        method: 'post',
        data
    })
}

export function myOrders(data) {
    let request = getRequestInterface()
    return request({
        url: '/order/myorders',
        method: 'post',
        data
    })
}

export function orderbook(data) {
    let request = getRequestInterface()
    return request({
        url: '/order/orderbook',
        method: 'post',
        data
    })
}

export function marketHistory(data) {
    let request = getRequestInterface()
    return request({
        url: '/markethistory',
        method: 'post',
        data
    })
}

export function lockedBalance(data) {
    let request = getRequestInterface()
    return request({
        url: '/lockedBalance',
        method: 'post',
        data
    })
}

export function getBalance(data) {
    let request = getRequestInterface()
    return request({
        url: '/getAssetBalance',
        method: 'post',
        data
    })
}

export function marketVolume(data) {
    let request = getRequestInterface()
    return request({
        url: '/marketVolume',
        method: 'post',
        data
    })
}

export function orderbookMarketPrice(data) {
    let request = getRequestInterface()
    return request({
        url: '/marketPrice',
        method: 'post',
        data
    })
}

export function klines(data) {
    let request = getRequestInterface()
    return request({
        url: '/klines',
        method: 'post',
        data
    })
}

export function indexPrice(data) {
    let request = getRequestInterface()
    return request({
        url: '/price',
        method: 'post',
        data
    })
}

export function getMarket(data) {
    let request = getRequestInterface()
    return request({
        url: '/getMarket',
        method: 'post',
        data
    })
}

export function getBridgeHistory(data) {
    let request = getRequestInterface()
    return request({
        url: '/bridge/history',
        method: 'post',
        data
    })
}
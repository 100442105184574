<template>
  <div class="mt-10 w-full text-center rounded-lg shadow-md sm:p-8 bg-[#0e131f] container mx-auto mb-10 px-4 h-full">
    <div v-if="lefttime > 0" class="my-auto h-full text-white text-6xl justify-center items-center flex flex-col space-y-20">
      <h1 class="flex flex-row justify-center items-center">
        <span><img class="h-[50px] mr-5" src="../assets/flame.svg" alt="" /></span>
        Yield-Farming countdown
      </h1>
      <span>{{ countdownText }}</span>
    </div>
    <div v-else>
      <h5 class="pt-5 md:pt-0 mb-2 text-3xl font-bold text-white text-left">Dashboard</h5>
      <div class="flex flex-row mb-10">
        <img class="" src="../assets/flame.svg" alt="" />
        <p class="pl-3 text-base text-gray-500 sm:text-lg dark:text-gray-400 text-left">Stake LP tokens and earn token rewards</p>
      </div>

      <div class="mx-auto grid lg:grid-cols-2">
        <div class="h-[450px] flex flex-col text-2xl rounded-lg text-white">
          <div class="mx-1 sm:mx-12 py-12 space-y-10 bg-[#111827] h-full">
            <div class="px-12 flex justify-between text-gray-400">
              <span>NDX Price</span>
              <span v-if="account !== 'Connect Wallet'" class="font-bold">${{ $format(ndx.price || 0, 6) }}</span>
              <span v-else class=" ">-</span>
            </div>
            <div class="px-12 flex flex-col text-gray-400 space-y-2">
              <span class="text-left">NDX to Harvest</span>

              <div class="flex justify-between items-center">
                <span v-if="account !== 'Connect Wallet'" class="text-xl">{{ $format(ndx.toHarvest || 0, 4) }}</span>
                <span v-else class=" ">-</span>
                <span v-if="account !== 'Connect Wallet'" class="text-sm">${{ $format(ndx.toHarvest * ndx.price || 0, 4) }}</span>
                <span v-else class=" ">-</span>
              </div>
            </div>
            <div class="px-12 flex flex-col text-gray-400 space-y-2">
              <span class="text-left">NDX in Wallet</span>
              <div class="flex justify-between items-center">
                <span v-if="account !== 'Connect Wallet'" class="text-xl">{{ $format(ndx.inWallet || 0) }}</span>
                <span v-else class=" ">-</span>
                <span v-if="account !== 'Connect Wallet'" class="text-sm">${{ $format(ndx.inWallet * ndx.price || 0, 4) }}</span>
                <span v-else class=" ">-</span>
              </div>
            </div>
            <div class="py-2 px-20">
              <SpinnerButtonCls cls="bg-gradient-to-r w-full from-blue-700 text-gray-50 hover:bg-slate-200 flex items-center py-2 mx-auto justify-center" @click="onHarvest" normalTxt="Harvest" loadingTxt="Harvesting" ref="btnHarvest"></SpinnerButtonCls>
            </div>
          </div>
        </div>

        <div class="h-[450px] flex flex-col text-2xl rounded-lg text-white mt-10 lg:mt-0">
          <div class="mx-1 sm:mx-12 py-12 space-y-10 bg-[#111827] h-full">
            <div class="px-12 flex flex-col text-gray-400 space-y-2">
              <span class="text-left">Locked Balance</span>
              <div class="flex justify-between items-center">
                <span v-if="account !== 'Connect Wallet'" class="text-xl">{{ $format(ndx.locked || 0, 4) }}</span>
                <span v-else class=" "></span>
                <span v-if="account !== 'Connect Wallet'" class="text-sm">${{ $format(ndx.locked * ndx.price || 0, 4) }}</span>
                <span v-else class=" ">-</span>
              </div>
            </div>

            <div class="px-12 flex flex-col text-gray-400 space-y-2">
              <span class="text-left">UnLocked Balance</span>
              <div class="flex justify-between items-center">
                <span v-if="account !== 'Connect Wallet'" class="text-xl">{{ $format(unlocked || 0, 4) }}</span>
                <span v-else class=" "></span>
                <span v-if="account !== 'Connect Wallet'" class="text-sm">${{ $format(unlocked * ndx.price || 0, 4) }}</span>
                <span v-else class=" ">-</span>
              </div>
            </div>

            <div class="pt-[80px] px-20 py-2">
              <SpinnerButtonCls cls="bg-gradient-to-r w-full from-blue-700 text-gray-50 hover:bg-slate-200 flex items-center py-2 mx-auto justify-center" @click="onClaim" normalTxt="Claim" loadingTxt="Claim" ref="btnClaim"></SpinnerButtonCls>
            </div>
          </div>
        </div>
      </div>

      <div v-if="farms.length > 0" class="mt-10">
        <div class="text-gray-400 mb-5">Yield farming has came to the end</div>
        <div class="" v-for="(farm, index) in farms" :key="index">
          <FarmDisclosure :farm="farm" />
        </div>
      </div>
      <div v-else>
        <div class="h-60 flex justify-center items-center text-white">
          <svg class="mr-3 h-5 w-5 animate-spin text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <TradeFooter />
</template>
<script>
import FarmDisclosure from '../components/FarmDisclosure.vue'
import TradeFooter from '../components/footer/TradeFooter.vue'
import { CONFIG } from '../config'
import { getNDXAmount, claimRewards, unlock, getToHarvest } from '../contract/farm'
import { ethers } from 'ethers'
import { mapGetters } from 'vuex'
import SpinnerButtonCls from '../components/SpinnerButtonCls.vue'

export default {
  name: 'faucet',
  components: [FarmDisclosure, TradeFooter, SpinnerButtonCls],
  computed: {
    ...mapGetters(['market', 'account', 'farms', 'ndx']),
  },
  data() {
    return {
      farmCfg: CONFIG.Farms,
      ndxAmount: 0,
      locked: 0,
      unlocked: 0,
      lefttime: 0,
      countdownText: '',
    }
  },
  watch: {
    async account(account, _) {
      await this.$store.dispatch('loadFarms', this.account)
      await this.loadData()
    },
  },
  async created() {
    this.$store.dispatch('loadFarms', this.account)
    await this.loadData()
  },
  async mounted() {
    // this.countDown()
  },
  methods: {
    async loadData() {
      let { inWallet, locked, unlocked } = await getNDXAmount(this.account)

      this.ndxAmount = Number(ethers.utils.formatEther(inWallet))
      this.locked = Number(ethers.utils.formatEther(locked))

      this.toHarvest = getToHarvest(this.farms, this.account)
      this.unlocked = Number(ethers.utils.formatEther(unlocked))
    },
    async onHarvest() {
      try {
        let pids = [0, 1]
        this.$refs.btnHarvest.isLoading = true
        await claimRewards(pids)
        this.$refs.btnHarvest.isLoading = false
        await this.$store.dispatch('loadFarms', this.account)
        await this.loadData()
      } catch (error) {
        this.$refs.btnHarvest.isLoading = false
      }
    },
    async onClaim() {
      try {
        this.$refs.btnClaim.isLoading = true
        await unlock()
        this.$refs.btnClaim.isLoading = false
      } catch (error) {
        this.$refs.btnClaim.isLoading = false
      }
    },
    addZero(i) {
      return i < 10 ? '0' + i : i + ''
    },
    countDown() {
      var endtime = 1680314400
      let lefttime = parseInt(endtime - Math.floor(Date.now() / 1000))
      var d = parseInt(lefttime / (24 * 60 * 60))
      var h = parseInt((lefttime / (60 * 60)) % 24)
      var m = parseInt((lefttime / 60) % 60)
      var s = parseInt(lefttime % 60)
      d = this.addZero(d)
      h = this.addZero(h)
      m = this.addZero(m)
      s = this.addZero(s)
      this.countdownText = `  ${d}: ${h} : ${m} : ${s}`
      this.lefttime = lefttime
      if (this.lefttime <= 0) {
        return
      }
      setTimeout(this.countDown, 1000)
    },
  },
}
</script>

<style scoped></style>

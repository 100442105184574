import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk'

const APP_NAME = 'NevDEX'
const APP_LOGO_URL = "https://nevdex.tech/assets/logo.2b38fec8.png"
const APP_SUPPORTED_CHAIN_IDS = [8453]

export const sdk = new CoinbaseWalletSDK({
  appName: APP_NAME,
  appLogoUrl: APP_LOGO_URL,
  appChainIds: APP_SUPPORTED_CHAIN_IDS,
})

// import {
//     BaseGoerli,
//     CeloAlfajoresTestnet,
//     Goerli,
//     Mumbai,
//     OptimismGoerli,
//     ScrollAlphaTestnet,
//     Sepolia,
//   } from "@thirdweb-dev/chains";

import { createThirdwebClient, defineChain, getContract, readContract, prepareContractCall, prepareTransaction, sendTransaction, toWei } from 'thirdweb'
import { createWallet } from 'thirdweb/wallets'

const baseSepolia = defineChain({
  id: 84532,
  rpc: 'https://sepolia.base.org',
})
const baseMainnet = defineChain({
  id: 8453,
  rpc: 'https://mainnet.base.org',
})

const client = createThirdwebClient({ clientId: 'c79c4bad501a27a81ebb3a137ab9ef40' })
const wallet = createWallet('com.coinbase.wallet')

export { baseSepolia, baseMainnet, client, wallet }

// https://portal.thirdweb.com/unity/wallets/actions/sign
// https://portal.thirdweb.com/typescript/v5/auth
// https://github.com/thirdweb-dev/smart_wallet_demo/blob/main/index.ts

<template>
  <div class="flex flex-col w-full h-full">
    <TradeBar />
    <div class="flex md:gap-0 gap-12 h-full w-full">
      <div class="flex flex-col-reverse md:flex-row flex-1">
        <div class="md:w-1/5 2xl:w-1/6 w-full border-black border-r">
          <div class="flex flex-col px-0.5 h-full">
            <div class="bg-gray-900 h-full">
              <div class="p-2 bg-gray-900 h-full">
                <ul class="flex rounded-lg divide-gray-200 shadow sm:flex">
                  <li class="w-full">
                    <button class="inline-block relative py-2 px-4 w-full text-sm font-medium text-center text-gray-50 bg-gray-800 hover:text-gray-700 hover:bg-gray-600 focus:bg-green-300" @click="toggleSide('buy')" v-bind:class="{ 'bg-green-300': side === 'buy' }">BUY</button>
                  </li>
                  <li class="w-full">
                    <button class="inline-block relative py-2 px-4 w-full text-sm font-medium text-center text-gray-50 bg-gray-800 hover:text-gray-700 hover:bg-gray-600 focus:bg-red-400" @click="toggleSide('sell')" v-bind:class="{ 'bg-red-400': side === 'sell' }">SELL</button>
                  </li>
                </ul>

                <!-- order type -->
                <div class="pt-2 text-sm font-medium text-center text-gray-500 border-b border-gray-600">
                  <ul class="flex flex-wrap -mb-px">
                    <li class="mr-2">
                      <a href="javascript:void(0)" :class="[orderType === 'market' ? 'border-blue-600 text-gray-300' : 'border-transparent', 'inline-block p-2 rounded-t-lg border-b-2  hover:text-gray-300 hover:border-blue-600']" @click="changeOrderType('market')">Market</a>
                    </li>
                    <li class="mr-2">
                      <a href="javascript:void(0)" :class="[orderType === 'limit' ? 'border-blue-600 text-gray-300' : 'border-transparent', 'inline-block p-2 rounded-t-lg border-b-2  hover:text-gray-300 hover:border-blue-600']" @click="changeOrderType('limit')">Limit</a>
                    </li>
                  </ul>
                </div>
                <!-- order type -->

                <!-- market order tab  -->
                <div v-if="orderType === 'market'">
                  <div class="mt-3 flex items-center rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                    <span class="w-3/12 text-gray-200 border border-gray-600 py-2 sm:text-sm rounded-l text-center"> Price </span>
                    <div class="relative w-9/12 flex items-center">
                      <input type="text" class="w-full absolute block pr-12 -top-4.5 border sm:text-sm text-gray-50 border-gray-500 text-center bg-gray-800" disabled :placeholder="orderbookPrice" />
                      <div class="absolute -top-2.5 right-2 text-gray-400 text-sm">{{ quoteToken }}</div>
                    </div>
                  </div>

                  <div class="mt-3 flex items-center rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                    <span v-if="side === 'buy'" class="w-3/12 text-gray-200 border border-gray-600 py-2 sm:text-sm rounded-l text-center"> Total </span>
                    <span v-else class="w-3/12 text-gray-200 border border-gray-600 py-2 sm:text-sm rounded-l text-center"> Size </span>
                    <div class="relative w-9/12 flex items-center">
                      <input type="number" class="w-full absolute block pr-12 -top-4.5 border sm:text-sm text-gray-50 border-gray-500 text-center bg-gray-800" step="0.001" :min="0" oninput="if(value<0)value=0" :value="total" @input="onMarketAmountChange($event.target.value)" />
                      <div class="absolute -top-2.5 right-2 text-gray-400 text-sm">{{ side === 'buy' ? quoteToken : baseToken }}</div>
                    </div>
                  </div>
                </div>
                <!-- market order tab  -->

                <!-- limit order tab  -->
                <div v-if="orderType === 'limit'">
                  <div class="mt-3 flex items-center rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                    <span class="w-3/12 text-gray-200 border border-gray-600 py-2 sm:text-sm rounded-l text-center"> Price </span>
                    <div class="relative w-9/12 flex items-center">
                      <input type="number" class="w-full absolute block pr-12 -top-4.5 border sm:text-sm text-gray-50 border-gray-500 text-center bg-gray-800" step="0.001" v-model="price" :min="0" oninput="if(value<0)value=0" />
                      <div class="absolute -top-2.5 right-2 text-gray-400 text-sm">{{ quoteToken }}</div>
                    </div>
                  </div>
                  <div class="mt-3 flex items-center rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500">
                    <span class="w-3/12 text-gray-200 border border-gray-600 py-2 sm:text-sm rounded-l text-center"> Size </span>
                    <div class="relative w-9/12 flex items-center">
                      <input type="number" class="w-full absolute block pr-12 -top-4.5 border sm:text-sm text-gray-50 border-gray-500 text-center bg-gray-800" step="0.001" :min="0" oninput="if(value<0)value=0" :value="total" @input="onLimitAmountChange($event.target.value)" />
                      <div class="absolute -top-2.5 right-2 text-gray-400 text-sm">{{ baseToken }}</div>
                    </div>
                  </div>
                </div>
                <!-- limit order tab  -->

                <RangeSlider @sliderPercentUpdate="updatePercent" :size="total" ref="rangeSlider" />
                <OrderSummary :orderType="orderType" :side="side" :total="total" :price="price"></OrderSummary>

                <div class="mt-2 flex justify-center items-center">
                  <button class="py-2 w-full bg-gray-800 hover:bg-gray-600 text-gray-50 uppercase" @click="onTrade">{{ side }}</button>
                </div>
                <Balance />
              </div>
            </div>
          </div>
        </div>

        <div class="md:w-1/5 2xl:w-1/6 w-full border-black border-r h-full bg-gray-900">
          <OrderBook @updateBid="onBidUpdate" @updateAsk="onAskUpdate" :baseToken="baseToken" :quoteToken="quoteToken" :market="market" />
        </div>

        <div class="flex flex-col md:w-3/5 2xl:w-4/6 w-full h-full bg-gray-900">
          <TradingView />
          <TradeTab class="h-[28vh] 2xl:h-[32vh]" />
        </div>
      </div>
    </div>
    <TradeFooter />
  </div>
</template>

<script>
import TradingView from '../components/TradingView.vue'
import TradeTab from '../components/UserTrade.vue'
import RangeSlider from '../components/RangeSlider.vue'
import OrderBook from '../components/OrderBook.vue'
import TradeFooter from '../components/footer/TradeFooter.vue'
import WalletModal from '../components/WalletModal.vue'
import Volume from '../components/Volume.vue'
import Balance from '../components/Balance.vue'
import TradeBar from '../components/TradeBar.vue'
import OrderSummary from '../components/OrderSummary.vue'
import { ethers } from 'ethers'
import { mapActions, mapGetters } from 'vuex'
import { getVaultBalance } from '../contract/contract'
import { buildOrder, placeOrder, orderbookMarketPrice } from '../api'
import { showToast } from '../utils/toast'
import { getChainConfig } from '../config'
// import { wallet, client, account as thirdWebAccount } from '../utils/coinbase'
import UseWallet from '../utils/wallet'
const { walletGlobal } = UseWallet()

export default {
  name: 'Trade',
  components: { TradingView, TradeTab, OrderBook, TradeFooter, WalletModal, Volume, Balance, TradeBar, RangeSlider, OrderSummary },
  computed: {
    ...mapGetters(['market', 'account', 'chainId']),
  },
  data() {
    return {
      side: 'buy',
      pools: [],
      price: 0,
      orderType: 'market',
      timer: '',
      baseToken: '',
      quoteToken: '',
      showCreateOrder: false,
      volume: 0,
      fee: 0,
      gas: 0,
      totalCost: 0,
      marketPrice: 0,
      orderbookPrice: 0,
      initialAmount: 0,
      total: 0,
    }
  },
  watch: {
    market: async function (newVal, _) {
      this.baseToken = newVal.split('-')[0]
      this.quoteToken = newVal.split('-')[1]
      this.market = newVal
      this.price = 0
      this.volume = 0
      this.$refs.rangeSlider.setValue(0)
      this.initialAmount = 0
      this.total = 0
      await this.getInitialAmount()

      this.orderbookPrice = this.$format(await orderbookMarketPrice({ marketID: newVal }), 4)
      this.price = this.orderType == 'market' ? this.orderbookPrice : 0
    },
    price: async function (price, _) {
      // this.getInitialAmount()
      if (this.orderType == 'limit' && this.side == 'buy' && this.price > 0) {
        this.initialAmount = (await this.getQuoteAmount()) / this.price
      }
    },
    account: async function (account, _) {
      this.initialAmount = await this.getQuoteAmount()
    },
    total: async function () {
      this.updateSliderPercent()
    },
  },
  methods: {
    ...mapActions(['updateOrderType']),
    onMarketAmountChange(amount) {
      this.total = amount
    },
    onLimitAmountChange(amount) {
      this.total = amount
    },
    updateSliderPercent() {
      let percent = (this.total / this.initialAmount) * 100
      console.log('updateSliderPercent', percent, this.total, this.initialAmount)
      this.$refs.rangeSlider.setValue(isNaN(percent) ? 0 : percent.toFixed(0))
    },
    async getBaseAmount() {
      return await getVaultBalance(this.baseToken, this.account)
    },
    async getQuoteAmount() {
      return await getVaultBalance(this.quoteToken, this.account)
    },
    async trade(side, orderType) {
      let price = this.orderType == 'market' ? this.orderbookPrice : this.price
      const cfg = getChainConfig(this.chainId)
      buildOrder({
        marketID: this.market,
        side: side,
        orderType: orderType,
        price: price.toString(),
        amount: this.total.toString(),
        expires: 3600,
        address: this.account,
      })
        .then(async (resp) => {
          let { id } = resp
          let orderSignature
          // if (cfg.SignOrder) {
          orderSignature = await this.signOrder(id)
          // }
          placeOrder({
            address: this.account,
            orderID: resp.id,
            signature: orderSignature,
          })
            .then(() => showToast('success', 'Order placed'))
            .catch((err) => console.error('==>!!!!!!! placeOrder', err))
        })
        .catch((err) => console.error('eeeee ==>', err))

      // .then(async (resp) => {
      //   let { id } = resp
      //   const { ethereum } = window
      //   const provider = new ethers.providers.Web3Provider(ethereum)
      //   const wallet = provider.getSigner()
      //   let messageHashBytes = ethers.utils.arrayify(id)
      //   let signature = await wallet.signMessage(messageHashBytes)
      //   const orderSignature = '0x' + signature.slice(130) + '0'.repeat(62) + signature.slice(2, 130)
      //   placeOrder({
      //     address: this.account,
      //     orderID: resp.id,
      //     signature: orderSignature,
      //   })
      //     .then(() => showToast('success', 'Order placed'))
      //     .catch((err) => console.error('==>!!!!!!! placeOrder', err))
      // })
      // .catch((err) => console.error('eeeee ==>', err))
    },
    async signOrder(id) {
      const messageHashBytes = ethers.utils.arrayify(id)
      const wallet = walletGlobal.signer
      let signature = await wallet.signMessage(messageHashBytes)
      const orderSignature = '0x' + signature.slice(130) + '0'.repeat(62) + signature.slice(2, 130)

      return orderSignature
    },
    async onTrade() {
      if (this.account === '') {
        showToast('danger', 'Please connect wallet first')
        return
      }
      if (this.orderType === 'market') {
        if (this.total == 0) {
          showToast('danger', 'Please input amount')
          return
        }
      } else {
        if (this.price == 0) {
          showToast('danger', 'Please input price')
          return
        }
        if (this.total == 0) {
          showToast('danger', 'Please input amount')
          return
        }
      }

      this.trade(this.side, this.orderType)
    },
    async toggleSide(side) {
      this.side = side
      this.price = 0
      this.total = 0
      this.initialAmount = 0
      await this.getInitialAmount()

      // reset slider percent to 0
      this.$refs.rangeSlider.setValue(0)
    },
    async changeOrderType(orderType) {
      console.log('changeOrderType')
      this.price = this.orderType == 'market' ? this.orderbookPrice : 0
      this.updateOrderType(orderType)
      this.orderType = orderType
      this.total = 0
      this.initialAmount = 0
      this.$refs.rangeSlider.setValue(0)

      await this.getInitialAmount()
    },
    onBidUpdate(bid) {
      console.log('onBidUpdate', bid.price, bid.size)
      if (this.orderType == 'market') {
        if (this.side == 'buy') {
          this.total = 0
        } else {
          this.total = bid.size
          this.initialAmount = bid.size
        }
      } else {
        this.price = bid.price
        this.total = bid.size
      }
    },
    onAskUpdate(ask) {
      if (this.orderType == 'market') {
      } else {
      }
    },
    async updatePercent(percent) {
      this.total = this.$format((this.initialAmount * percent) / 100, 4)
    },
    async getInitialAmount() {
      if (this.orderType == 'market')
        if (this.side == 'buy') {
          this.initialAmount = await this.getQuoteAmount()
          console.log('market buy')
        } else {
          console.log('market sell')
          this.initialAmount = await this.getBaseAmount()
        }
      else {
        // limit
        if (this.side == 'buy') {
          console.log('limit buy')
          if (this.price > 0) {
            this.initialAmount = (await this.getQuoteAmount()) / this.price
          }
        } else {
          console.log('limit sell')
          this.initialAmount = await this.getBaseAmount()
        }
      }
    },
    async tick() {
      this.orderbookPrice = this.$format(await orderbookMarketPrice({ marketID: this.market }), 4)
    },
  },
  async mounted() {
    let tokens = this.market.split('-')
    this.baseToken = tokens[0]
    this.quoteToken = tokens[1]
    this.timer = setInterval(this.tick, 2000)
    this.initialAmount = await this.getQuoteAmount()
  },
  beforeUnmount() {
    clearTimeout(this.timer)
  },
}
</script>
<style scoped></style>

<template>
  <div class="w-full p-1 text-xs space-y-1">
    <div class="flex flex-row justify-between">
      <span class="text-gray-400">Order</span>
      <span class="text-gray-400">{{ subTotal_.toFixed(3) }} {{ store.getters.quoteToken }}</span>
    </div>
    <div class="flex flex-row justify-between">
      <span class="text-gray-400">Fee({{ feeRate_ }}%)</span>
      <span class="text-gray-400">{{ fee_.toFixed(3) }} {{ store.getters.quoteToken }}</span>
    </div>
    <!-- <div class="flex flex-row justify-between">
      <span class="text-gray-400">Total</span>
      <span class="text-gray-400">{{ total_.toFixed(3) }} {{ store.getters.quoteToken }}</span>
    </div> -->
  </div>
</template>
<script setup>
import { ref, onMounted , watch} from 'vue'
import { useStore } from 'vuex'
import { calculateTrade, feeRateAfterDiscount } from '../utils/fee'
import BigNumber from 'bignumber.js'
import { getBalance } from '../contract/contract'
import { getMarket } from '../api'

const store = useStore()
const props = defineProps(['orderType', 'side', 'total', 'price'])
const subTotal_ = ref(0)
const fee_ = ref(0)
const total_ = ref(0)
const feeRate_ = ref(0.2)

watch(
  () => [props.orderType, props.side, props.total, props.price],
  async () => {
    const marketInfo = store.state.marketInfo
    let market = marketInfo[store.state.market]
    if (!market) {
      market = await getMarket({
        marketID: store.state.market,
      })
      store.commit('setMarketInfo', market)
    }

    let orderType = props.orderType
    let side = props.side
    let total = Number(props.total)
    const asMakerFeeRate = new BigNumber(0)
    const asTakerFeeRate = new BigNumber(market.takerFeeRate)
    const amountDecimals = new BigNumber(5)
    const priceDecimals = Number(market.priceDecimals)
    const amount = new BigNumber(total)
    const ndxAmount = new BigNumber(await getBalance('NDX', store.state.account))
    const price = new BigNumber(Number(props.price))

    const param = { orderType, side, price, amount, ndxAmount, asMakerFeeRate, asTakerFeeRate, amountDecimals, priceDecimals }
    const result = calculateTrade(param)

    subTotal_.value = Number(result.subtotal)
    fee_.value = Number(result.tradeFee)
    total_.value = Number(result.totalQuoteTokens)
  }
)

onMounted(async () => {})
</script>
<style></style>

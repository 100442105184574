const state = {
    importNftTokenId: 0,
    receiverChain: 0,
}

const mutations = {
    // base contract
    updateImportNftTokenId(state, payload) {
        // console.log('updateImportNftTokenId', payload)
        state.importNftTokenId = payload
    },
    updateReceiverChain(state, payload) {
        // console.log('updateReceiverChain', payload)
        state.receiverChain = payload
    },
}

const actions = {

}

const getters = {
    importNftTokenId: (state) => state.importNftTokenId,
    receiverChain: (state) => state.receiverChain,
}

export default {
    state,
    mutations,
    actions,
    getters,
}

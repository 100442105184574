<template>
  <div class="mt-5 mb-10 px-3">
    <VueSlider
      v-model="value"
      :adsorb="true"
      :interval="25"
      :marks="marks"
      dot-size="14"
      :tooltip-formatter="formatter"
      :labelActiveStyle="labelActiveStyle"
      @drag-start="() => (inDragging = true)"
      @dragging="dragging"
      @drag-end="
        () => {
          inDragging = false
        }
      "
      @click="$emit('sliderPercentUpdate', value)"
      ref="slider"
    >
      <template v-slot:step="{ active }">
        <div :class="['custom-step', { active }]"></div>
      </template>
    </VueSlider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    VueSlider,
  },
  props: ['size'],
  data: function () {
    return {
      inDragging: false,
      value: 0,
      formatter: '{value}%',
      marks: (val) =>
        val % 25 === 0
          ? {
              label: `${val}%`,
              labelStyle: {},
              labelActiveStyle: {
                color: '#9CA3AF',
              },
            }
          : false,
    }
  },
  mounted() {},
  methods: {
    dragging() {
      if (!this.inDragging) return
      this.$emit('sliderPercentUpdate', this.value)
    },
    setValue(val) {
      this.$refs.slider.setValue(val)
    },
  },
}
</script>

<style>
.custom-step {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #4b5563;
  background-color: #4b5563;
}

.custom-step.active {
  box-shadow: 0 0 0 3px #0075ff;
  background-color: #4b5563;
}

/* process style */
.vue-slider-process {
  background-color: #0075ff;
  border-radius: 15px;
}

.vue-slider-dot-tooltip-inner {
  font-size: 14px;
  white-space: nowrap;
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  border-color: #4b5563;
  background-color: #4b5563;
  box-sizing: content-box;
}

.vue-slider-mark-label {
  font-size: 14px;
  white-space: nowrap;
  color: #9ca3af;
  font-size: 10px;
}

.vue-slider-rail {
  background-color: #4b5563;
  border-radius: 15px;
}

.vue-slider-mark-label {
  color: #9ca3af;
}
</style>

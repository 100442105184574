<template>
  <div class="w-[680px] mx-auto z-10">
    <div class="flex text-white bg-transparent flex-col w-full mt-5 items-center mx-auto">
      <div class="border border-gray-600 rounded-sm w-full px-10 pb-10">
        <div class="flex flex-col w-full">
          <span class="font-bold mt-5 flex justify-start text-xl w-2/3">Import an NFT</span>
          <span class="mt-2 flex justify-start text-gray-400 text-sm">Import an NFT on the sender blockchain.</span>
          <NFTDropdown class="mt-5 w-1/3"></NFTDropdown>
        </div>
      </div>
    </div>

    <div class="flex text-white bg-transparent flex-col w-full mt-5 items-center mx-auto">
      <div class="border border-gray-600 rounded-sm w-full px-10">
        <div class="flex flex-row w-full">
          <span class="flex justify-start font-bold mt-5 text-xl">Select Receiver Blockchain</span>
        </div>
        <div class="h-20 w-full">
          <ReceiverBlockchain class="mt-5"></ReceiverBlockchain>
        </div>
      </div>
    </div>

    <div class="flex text-white bg-transparent flex-col w-full mt-5 items-center mx-auto">
      <div class="border border-gray-600 rounded-sm w-full px-10">
        <div class="flex flex-row w-full">
          <span class="flex justify-start font-bold mt-5 text-xl">Load NDX</span>
        </div>
        <div class="h-48 w-full space-y-10 mt-6">
          <div class="flex flex-row items-center">
            <div class="w-1/3 text-left">
              <span>Input Amount :</span>
            </div>
            <div class="w-2/3">
              <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" placeholder="NDX amount" ref="amount" />
            </div>
          </div>
          <button v-if="showAttach" class="bg-gradient-to-r w-full from-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4" @click="attach">Attach</button>
          <button v-if="showSend" class="bg-gradient-to-r w-full from-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4" @click="transfer">Confirm Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NFTDropdown from './NFTDropdown.vue'
import ReceiverBlockchain from './ReceiverBlockchain.vue'
import store from '../../store'
import { showToast } from '../../utils/toast'
import { approve, sendNFT } from '../../contract/bridge'

export default {
  data() {
    return {
      amount: 0,
      showAttach: true,
      showSend: false,
    }
  },
  components: { ReceiverBlockchain, NFTDropdown },
  computed: {
    ...mapGetters(['account', 'chainId', 'importNftTokenId', 'receiverChain']),
  },

  mounted() {},
  methods: {
    valid() {
      let nftTokenId = this.importNftTokenId
      let receiverChainId = this.receiverChain
      this.amount = this.$refs.amount.value

      console.log('amount', this.amount)
      if (this.account == 'Connect Wallet') {
        showToast('danger', 'Connect wallet first!')
        return false
      }

      // if (nftTokenId == 0) {
      //   showToast('danger', 'No NFT selected!')
      //   return
      // }

      if (receiverChainId == 0) {
        showToast('danger', 'No receiver chain selected!')
        return false
      }

      if (this.chainId == receiverChainId) {
        showToast('danger', 'Sender and receiver chains are same!')
        return false
      }

      if (this.amount == 0) {
        showToast('danger', 'Amount must be greater than 0!')
        return false
      }

      return true
    },
    async attach() {
      if (!this.valid()) {
        return
      }
      // approve bridge NDX amount
      await approve(this.amount)
      this.showAttach = false
      this.showSend = true
    },

    async transfer() {
      let nftTokenId = this.importNftTokenId
      let receiverChainId = this.receiverChain
      console.log('sender chain', this.chainId, 'receiver chain', receiverChainId)
      await sendNFT(this.account, nftTokenId, this.chainId, receiverChainId)
    },
  },
}
</script>

<style scoped></style>

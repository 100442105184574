import store from "../store/index"
import { createThirdwebClient, defineChain, getContract, readContract, prepareContractCall, prepareTransaction, sendTransaction, toWei } from 'thirdweb'
import abiERC20 from "../abi/IERC20.json"
import abiVault from "../abi/vault.json"
import { client, baseSepolia, wallet } from "../utils/coinbase"

function getTokenContract(address) {
    const contracts = store.getters.tokenContracts
    let tokenContract = contracts[address]
    if (tokenContract == null) {
        const contract = getContract({
            client,
            chain: baseSepolia,
            address: address,
            abi: abiERC20,
        })
        store.commit('updateTokenContract', { address, contract })
        tokenContract = contract
    }

    return tokenContract
}

function getVaultContract(address) {
    const contracts = store.getters.vaultContracts
    let vaultContract = contracts[address]
    if (vaultContract == null) {
        const contract = getContract({
            client,
            chain: baseSepolia,
            address: address,
            abi: abiVault,
        })
        store.commit('updateVaultContract', { address, contract })
    }

    return vaultContract
}

async function getBalance(address, account) {
    const contracts = store.getters.tokenContracts
    // console.log('contracts', contracts)
    let tokenContract = getTokenContract(address)
    const balance = await readContract({
        contract: tokenContract,
        method: 'function balanceOf(address) view returns (uint256)',
        params: [account],
    })

    return balance
}

async function getVaultBalance(vaultAddress, tokenAddress, account) {
    let vaultContract = getVaultContract(vaultAddress)
    const balance = await readContract({
        contract: vaultContract,
        method: 'function balances(address,address) view returns (uint256)',
        params: [tokenAddress, account],
    })

    return balance
}

async function mint(address, userAddress, amount) {
    console.log('coinbase mint', address, userAddress)
    let tokenContract = getTokenContract(address)
    const transaction = prepareContractCall({
        contract: tokenContract,
        method: 'function mint(address to, uint256 amount)',
        params: [userAddress, toWei('10000')],
    })

    console.log('transaction', transaction)
    const account = await wallet.connect({ client })
    const transactionResult = await sendTransaction({
        account,
        transaction,
        wallet,
    })

    console.log(transactionResult)
}

async function allowance(tokenAddress, owner, vaultAddress) {
    const contract = getTokenContract(tokenAddress)
    const balance = await readContract({
        contract: contract,
        method: 'function allowance(address,address) view returns (uint256)',
        params: [owner, vaultAddress],
    })

    return balance
}

async function approve(tokenAddress, vaultAddress, amount) {
    const tokenContract = getTokenContract(tokenAddress)
    const transaction = prepareContractCall({
        contract: tokenContract,
        method: 'function approve(address,uint256)',
        params: [vaultAddress, toWei(amount)],
    })

    const account = await wallet.connect({ client })
    const transactionResult = await sendTransaction({
        account,
        transaction,
        wallet,
    })
}

async function deposit(tokenAddress, vaultAddress, amount) {
    const vaultContract = getVaultContract(vaultAddress)
    const transaction = prepareContractCall({
        contract: vaultContract,
        method: 'function deposit(address to, uint256 amount)',
        params: [tokenAddress, toWei(amount)],
    })

    const account = await wallet.connect({ client })
    const transactionResult = await sendTransaction({
        account,
        transaction,
        wallet,
    })
}

async function withdraw(tokenAddress, vaultAddress, amount) {
    const vaultContract = getVaultContract(vaultAddress)
    const transaction = prepareContractCall({
        contract: vaultContract,
        method: 'function withdraw(address to, uint256 amount)',
        params: [tokenAddress, toWei(amount)],
    })

    const account = await wallet.connect({ client })
    const transactionResult = await sendTransaction({
        account,
        transaction,
        wallet,
    })
}

export {
    getBalance,
    getVaultBalance,
    mint,
    allowance,
    approve,
    deposit,
    withdraw
}
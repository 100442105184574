import FarmConf from './config_farm'
import CHAIN from './network'

const NETWORK = 'mainnet'

const CONFIG_LIST = {
  mainnet: {
    ChainId: 71402,
    VaultAddress: '0xAb22Df0a98cC6286791Cfa70f0F0603F21435026',
    MasterGardener: '0x274e13daf1C9795d7B48A637de8910662f0F6af1',
    NDXAddress: '0x571cBe5175CF64f73ccC7301e70d04a1b494Ea3e',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    BridgeAddress: '',
    NFTAddress: '0xd16a99755a06906613e85B4f7d0093f06605C8a2',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'https://mainnet.nevdex.tech/api/',
    Mainnet: true,
    Logo: 'godwoken.svg',
    SignOrder: true,
  },
  base: {
    ChainId: 8453,
    VaultAddress: '0x02e3E6AF11edF5423e52A2446ae975A76C722192',
    MasterGardener: '0x274e13daf1C9795d7B48A637de8910662f0F6af1',
    NDXAddress: '0x1B95Ee540811AFfA6e784300315Cca0abF74Eb29',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    BridgeAddress: '0x43ecc81EF2762324C2D767C8d3b3d45cE62A462C',
    NFTAddress: '0x4e87903744893C163115ee93f71419a2E452D416',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5050/api/',
    Mainnet: true,
    Logo: 'base.webp',
    SignOrder: false,
  },
  foundry: {
    ChainId: 31337,
    VaultAddress: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
    MasterGardener: '',
    NDXAddress: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    MultiCallAddress: '',
    BridgeAddress: '',
    NFTAddress: '',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5050/api/', //https://testnet.nevdex.tech/api/
    Mainnet: false,
    Logo: 'godwoken.svg',
    SignOrder: false,
  },
  testnet: {
    ChainId: 71401,
    VaultAddress: '0x489f69D6a90F6e515DF9D4A954B7cC4051DCBd48',
    MasterGardener: '0x2B80903225A94F955addeDBf1E65Bf4C84502B31',
    NDXAddress: '0x7455b2B6a77079F37F1b265dBd39C5aB4D0DfB7b',
    MultiCallAddress: '0xaB257428B2F56516F8aDb3c4c6762bDD50b7a118', // 0x299783039957CD900EEafdaBA9da65ed2facA229
    BridgeAddress: '0x0A58F852049285571e6bB697D07Fd4c3e8E7b96f',
    NFTAddress: '0xE4389F8238a7424166cea1Db9955718F6f49CB63',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5050/api/', //https://testnet.nevdex.tech/api/
    Mainnet: false,
    Logo: 'godwoken.svg',
    SignOrder: false,
  },
  fantomTestnet: {
    ChainId: 4002,
    VaultAddress: '0x3Ba3957F5F268183768370772309ff3Dfb9e22d5',
    MasterGardener: '0x274e13daf1C9795d7B48A637de8910662f0F6af1',
    NDXAddress: '0xbdd02C463bF517128A0B7ec50bb13Bf011C66B78',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    BridgeAddress: '0x5bc790Fa81FC9b81e46F6EB622d55A56e6b260ea',
    NFTAddress: '0x7641E2b37FEA5F1f3d5335936b1Bb58f0C5c3125',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5050/api/',
    Mainnet: false,
    Logo: 'fantom.png',
    SignOrder: false,
  },
  fantomSonic: {
    ChainId: 64240,
    VaultAddress: '0x4744A2bD04ED29CCf5A3747e3516595fa33330ae',
    MasterGardener: '0x274e13daf1C9795d7B48A637de8910662f0F6af1',
    NDXAddress: '0x2365Dc3095AE3b6c65d9a8fAF1F300043caAB77C',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5050/api/',
    Mainnet: false,
    Logo: 'fantom.png',
    SignOrder: false,
  },
  baseGoerli: {
    ChainId: 84531,
    VaultAddress: '0x4aB3D20eEe122CDa07aB8f35e13C86BC917a0F90',
    MasterGardener: '0x274e13daf1C9795d7B48A637de8910662f0F6af1',
    NDXAddress: '0x571cBe5175CF64f73ccC7301e70d04a1b494Ea3e',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5050/api/',
    Mainnet: false,
    Logo: 'base.webp',
    SignOrder: false,
  },
  baseSepolia: {
    ChainId: 84532,
    VaultAddress: '0x84568280CaC3444962Fb76dFB33D621CD921ea0a',
    MasterGardener: '0x274e13daf1C9795d7B48A637de8910662f0F6af1',
    NDXAddress: '0x02810d142Ded01aFa3C7620a4fe9322a4c5653af',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    BridgeAddress: '0x43ecc81EF2762324C2D767C8d3b3d45cE62A462C',
    NFTAddress: '0x4e87903744893C163115ee93f71419a2E452D416',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5050/api/',
    Mainnet: false,
    Logo: 'base.webp',
    SignOrder: false,
  },
  polygonMumbai: {
    ChainId: 80001,
    VaultAddress: '0x4A6399dd4D612090CA668E6BB291a742F837a741',
    MasterGardener: ' ',
    NDXAddress: '0x4aB3D20eEe122CDa07aB8f35e13C86BC917a0F90',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5053/api/',
    Mainnet: false,
    Logo: 'polygon.png',
    SignOrder: false,
  },
  avalancheFuji: {
    ChainId: 43113,
    VaultAddress: '0xed915AA95C0aC1578e4D1718d49D48B72aAB41C0',
    MasterGardener: ' ',
    NDXAddress: '0x22c0DB4CC9B339E34956A5699E5E95dC0E00c800',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5054/api/',
    Mainnet: false,
    Logo: 'avalanche.png',
    SignOrder: false,
  },
  modeTestnet: {
    ChainId: 919,
    VaultAddress: '0xb3e06b61252E9F36c4d02a6a495dDF8D6218e556',
    MasterGardener: ' ',
    NDXAddress: '0xed915AA95C0aC1578e4D1718d49D48B72aAB41C0',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    BridgeAddress: '',
    NFT: '',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5055/api/',
    Mainnet: false,
    Logo: 'mode.webp',
    SignOrder: false,
  },
  modeMainnet: {
    ChainId: 34443,
    VaultAddress: '0xb3e06b61252E9F36c4d02a6a495dDF8D6218e556',
    MasterGardener: ' ',
    NDXAddress: '0xeE1b67de81000336bADF6f69fE1eb9702c054F4B',
    MultiCallAddress: '0x4949A80Cf451d763d9ABC09aec3b5Eb46aFFFc3A',
    BridgeAddress: '',
    NFT: '',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5055/api/',
    Mainnet: false,
    Logo: 'mode.webp',
    SignOrder: false,
  },
  anvilTestnet: {
    ChainId: 31337,
    VaultAddress: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
    MasterGardener: '',
    NDXAddress: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
    MultiCallAddress: '',
    Farms: FarmConf[NETWORK],
    BaseUrl: 'http://127.0.0.1:5050/api/',
    Mainnet: false,
    Logo: 'anvil.png',
    SignOrder: false,
  },
}

function getSupportedChain() {
  const supportChain = ['mainnet', 'base', 'fantomTestnet', 'modeMainnet']
  let chains = Object.keys(CONFIG_LIST)
    .filter((chain) => supportChain.includes(chain))
    .map((x) => {
      let cfg = CONFIG_LIST[x]
      cfg.ChainName = CHAIN[CONFIG_LIST[x].ChainId].chainName
      return cfg
    })
  console.log('chains', chains)
  return chains
}

function getBridgedChain() {
  const supportChain = ['testnet', 'baseSepolia']
  let chains = Object.keys(CONFIG_LIST)
    .filter((chain) => supportChain.includes(chain))
    .map((x) => {
      let cfg = CONFIG_LIST[x]
      cfg.ChainName = CHAIN[CONFIG_LIST[x].ChainId].chainName
      return cfg
    })
  // console.log('chains', chains)
  return chains
}

function getVaultAddress() {
  let chain = Object.keys(CONFIG_LIST).filter((chain) => CONFIG_LIST[chain].ChainId == getChainId())
  let vaultAddress = CONFIG_LIST[chain[0]].VaultAddress
  return vaultAddress
}

function getChainId() {
  let chainId = window.ethereum.chainId
  const decimalChainId = parseInt(chainId, 16)
  // console.log('get chain id', decimalChainId)
  return decimalChainId
}

function getBridgeAddress() {
  let chain = Object.keys(CONFIG_LIST).filter((chain) => CONFIG_LIST[chain].ChainId == getChainId())
  console.log('...getBridgeAddress')
  // console.log(chain)
  let bridgeAddress = CONFIG_LIST[chain[0]].BridgeAddress
  console.log(bridgeAddress)
  return bridgeAddress
}

function getNFTAddress() {
  let chain = Object.keys(CONFIG_LIST).filter((chain) => CONFIG_LIST[chain].ChainId == getChainId())
  console.log('...getNFTAddress')
  console.log(chain)
  let nftAddress = CONFIG_LIST[chain[0]].NFTAddress
  console.log(nftAddress)
  return nftAddress
}

function getNDXAddress() {
  let chain = Object.keys(CONFIG_LIST).filter((chain) => CONFIG_LIST[chain].ChainId == getChainId())
  console.log('...getNDXAddress')
  // console.log(chain)
  let ndxAddress = CONFIG_LIST[chain[0]].NDXAddress
  console.log(ndxAddress)
  return ndxAddress
}

function getChainConfig(chainId) {
  for (let key in CONFIG_LIST) {
    const config = CONFIG_LIST[key]
    // console.log('config', config)
    if (config.ChainId == chainId) return config
  }
}

const CONFIG = CONFIG_LIST[NETWORK]

export default NETWORK

import CHUCK from '../assets/token/CHUCK.png'
import PEPE from '../assets/token/PEPE.webp'
import BREET from '../assets/token/BREET.jpeg'
import BTC from '../assets/token/BTC.webp'
import CKB from '../assets/token/CKB.svg'
import RUN from '../assets/token/RUN.png'
import YOK from '../assets/token/YOK.png'
import BRN from '../assets/token/BRN.svg'
import BNB from '../assets/token/BNB.svg'
import NDX from '../assets/token/NDX.png'
import USDT from '../assets/token/USDT.webp'
import USDC from '../assets/token/USDC.svg'
import OVUM from '../assets/token/OVUM.png'
import Transinu from '../assets/token/Transinu.png'
import FTM from '../assets/token/FTM.webp'
import { flare } from 'viem/chains'

let tokenLogos = {}

tokenLogos['CHUCK'] = CHUCK
tokenLogos['PEPE'] = PEPE
tokenLogos['BREET'] = BREET
tokenLogos['BTC'] = BTC
tokenLogos['CKB'] = CKB
tokenLogos['RUN'] = RUN
tokenLogos['YOK'] = YOK
tokenLogos['BRN'] = BRN
tokenLogos['NDX'] = NDX
tokenLogos['BNB'] = BNB
tokenLogos['USDT'] = USDT
tokenLogos['USDC'] = USDC
tokenLogos['OVUM'] = OVUM
tokenLogos['Transinu'] = Transinu
tokenLogos['FTM'] = FTM

export { CONFIG, CONFIG_LIST, getChainId, getSupportedChain, getVaultAddress, getBridgeAddress, getNFTAddress, getNDXAddress, getBridgedChain, tokenLogos, getChainConfig }

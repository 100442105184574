<template>
  <div class="flex sm:flex-row flex-col bg-gray-900 border-black border-b border-t md:py-0 pb-3">
    <div class="md:w-1/4 2xl:w-1/5 w-full mt-0.5">
      <MarketSelect class="" @selectMarket="updateSelectMarket" />
    </div>
    <div class="w-full">
      <Volume :baseToken="CKB" />
    </div>
  </div>
</template>

<script>
import MarketSelect from './MarketSelect.vue'
import Volume from './Volume.vue'
export default {
  components: { MarketSelect, Volume },
}
</script>

<style></style>

<template>
  <div class="w-full text-center rounded-lg shadow-md sm:p-8 mx-auto h-full bg-image">
    <h1 class="text-white text-5xl font-semibold">Mint a Bull</h1>
    <div class="flex flex-col justify-center w-80 mx-auto mt-10 border-4 border-gradient rounded">
      <div v-if="loading" role="status" class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center">
        <div class="flex items-center justify-center w-full h-[400px] bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
          <svg class="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
        </div>
      </div>
      <img v-else class="object-cover h-[400px]" :src="currentImg" alt="" />
    </div>

    <div class="text-white mt-10">
      <span>{{ nftAddress() }}</span>
    </div>

    <div class="flex flex-col space-y-5 mt-10 w-40 justify-center mx-auto">
      <SpinnerButtonCls v-if="!checkAllowance" cls="flex flex-row justify-center text-white bg-gradient-to-r from-blue-700 py-2" @click="onApprove" normalTxt="Approve" ref="approveButton" />
      <SpinnerButtonCls v-else cls="flex flex-row justify-center text-white bg-gradient-to-r from-blue-700 py-2" @click="onMint" normalTxt="Mint" ref="mintButton" />
    </div>
  </div>
  <TradeFooter class="absolute bottom-0 w-full" />
</template>

<script>
import { getBridgeAddress, getNDXAddress, getNFTAddress } from '../config'
import { approveNFTContract, mintNFTWithNDX, getNFTMintPrice, getAllowance, currentId } from '../contract/nft_mint'
import { mapGetters } from 'vuex'
import abiERC20 from '../abi/erc20.json'
import UseWallet from '../utils/wallet'
import TradeFooter from '../components/footer/TradeFooter.vue'
import SpinnerButtonCls from '../components/SpinnerButtonCls.vue'
import { showToast } from '../utils/toast'
import { getOwnedTokens } from '../contract/nft'
const { walletGlobal } = UseWallet()

export default {
  data() {
    return {
      ndxAllowance: 0,
      mintPrice: 1,
      currentImg: '',
      loading: true,
    }
  },
  components: [SpinnerButtonCls],
  computed: {
    ...mapGetters(['account', 'chainId']),
    checkAllowance() {
      return this.ndxAllowance >= this.mintPrice
    },
  },
  watch: {
    chainId: async function (newVal, oldVal) {
      await this.check()
    },
    account: async function (newVal, oldVal) {
      await this.check()
    },
  },
  methods: {
    async check() {
      this.mintPrice = await getNFTMintPrice(getNFTAddress())
      if (this.account == 'Connect Wallet') {
        return
      }
      const ndxAllowance = await getAllowance(getNDXAddress(), this.account, getNFTAddress())
      const id = await currentId(getNFTAddress())
      this.currentImg = `https://gateway.pinata.cloud/ipfs/QmaVQQRvEqj4k3s8okhmGLmhdVu8UMWHKAUGVMG7nRhZE6/${id}.avif`
      this.loading = false
    },
    nftAddress() {
      return getNFTAddress()
    },
    async checkLimit() {
      let nfts = await getOwnedTokens(this.account)
      if (nfts.length >= 5) {
        return false
      }
      return
    },
    async onApprove() {
      if (this.account == 'Connect Wallet') {
        showToast('danger', 'Connect wallet first!')
        return
      }
      if (!this.checkLimit()) {
        showToast('danger', 'Maximum 5 Bull NFTs each wallet !')
        return
      }

      try {
        this.$refs.approveButton.isLoading = true
        await approveNFTContract(getNFTAddress(), getNDXAddress(), this.mintPrice)
        this.$refs.approveButton.isLoading = false
        this.ndxAllowance = this.mintPrice
      } catch (error) {
        this.$refs.approveButton.isLoading = false
      }
    },
    async onMint() {
      if (this.account == 'Connect Wallet') {
        showToast('danger', 'Connect wallet first!')
        return
      }
      if (!this.checkLimit()) {
        showToast('danger', 'Maximum 5 Bull NFTs each wallet !')
        return
      }

      const ndxAllowance = await getAllowance(getNDXAddress(), this.account, getNFTAddress())
      this.$refs.mintButton.isLoading = true
      await mintNFTWithNDX(getNFTAddress())
      this.$refs.mintButton.isLoading = false
      showToast('success', 'Bull Minted !')
      await this.check()
    },
  },
  async created() {
    this.mintPrice = await getNFTMintPrice(getNFTAddress())
    this.ndxAllowance = await getAllowance(getNDXAddress(), this.account, getNFTAddress())
  },

  async created() {
    console.log('created')
  },

  async mounted() {
    const id = await currentId(getNFTAddress())
    this.currentImg = `https://gateway.pinata.cloud/ipfs/QmaVQQRvEqj4k3s8okhmGLmhdVu8UMWHKAUGVMG7nRhZE6/${id}.avif`
    this.loading = false

    await this.check()
  },
}
</script>

<style scoped>
.border-gradient {
  border-image: linear-gradient(to right, #212737, #1a3fc2);
  border-image-slice: 1;
}
</style>

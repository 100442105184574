<template>
  <div class="flex flex-row items-center" @click="onSwitchNetwork">
    <Popover v-slot="{ open }" class="relative">
      <PopoverButton class="group inline-flex items-center py-2 text-base font-medium hover:text-white outline-none">
        <span class="ml flex flex-row space-x-3">
          <div v-if="correctNetwork">
            <img :src="`${chain.Logo}`" alt="" class="h-6 rounded-full" />
          </div>
          <div v-else>
            <img src="../assets/wrong_network.svg" alt="" class="h-6" />
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#98A1C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mt-[2px]"><polyline points="6 9 12 15 18 9"></polyline></svg>
        </span>
        <ChevronDownIcon :class="open ? ' ' : ' '" class="ml-2 h-5 w-5 transition duration-150 ease-in-out group-hover:text-orange-300/80" aria-hidden="true" />
      </PopoverButton>

      <transition enter-active-class="transition duration-200 ease-out" enter-from-class="translate-y-1 opacity-0" enter-to-class="translate-y-0 opacity-100" leave-active-class="transition duration-150 ease-in" leave-from-class="translate-y-0 opacity-100" leave-to-class="translate-y-1 opacity-0">
        <PopoverPanel v-slot="{ close }" class="absolute -left-8 z-10 mt-3 sm:px-0 lg:max-w-3xl w-[16rem]">
          <div class="overflow-hidden rounded-md shadow-lg bg-slate-800">
            <div class="space-y-2">
              <li v-for="(item, index) in supportedChain" :key="index" @click="accept(close, item)">
                <a href="#" class="flex p-2 text-base font-bold text-gray-900 hover:bg-gray-600 group hover:shadow">
                  <img class="h-[1.5rem] rounded-full" :src="`${item.Logo}`" alt="logo" />
                  <span class="flex-1 ml-3 whitespace-nowrap text-white">{{ item.ChainName }}</span>
                </a>
              </li>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { getSupportedChain } from '../config'
import NETWORK from '../config/network'
import store from '../store'
import { ethers } from 'ethers'
import { changeNetwork as walletChangeNetwork } from '../utils/wallet'

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  data() {
    return {
      correctNetwork: true,
      switchNetwork: false,
    }
  },
  computed: {
    ...mapGetters(['chain', 'chainId']),
    supportedChain() {
      return getSupportedChain()
    },
  },
  methods: {
    async accept(close, item) {
      ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [NETWORK[item.ChainId]],
      })
      close()
      if (this.chain.ChainId == item.ChainId) {
        return
      }
      // this.switchNetwork = true
    },
    checkNetwork(chainId) {
      const supportedChain = getSupportedChain()
      for (let i = 0; i < supportedChain.length; i++) {
        const chain = supportedChain[i]
        if (chain.ChainId == chainId) {
          this.correctNetwork = true
          store.commit('setChain', chain)
          return
        }
      }
      console.log('...wrong network', chainId)
      this.correctNetwork = false
    },
    async onNetworkChange() {
      try {
        const chainId = parseInt(window.ethereum.chainId, 16)
        this.checkNetwork(chainId)
        if (this.correctNetwork) {
          await walletChangeNetwork()
        }
      } catch (error) {
        console.log('onNetworkChange', error)
      }
    },
  },
  mounted: async function () {
    const provider = new ethers.providers.Web3Provider(ethereum)
    const { chainId } = await provider.getNetwork()
    this.checkNetwork(chainId)
    window.ethereum.on('chainChanged', this.onNetworkChange)
  },
}
</script>

<style></style>

import { ethers } from 'ethers';
import BigNumber from 'bignumber.js'
import { CONFIG } from "../config"
import MasterGardener from "../abi/MasterGardener.json"
import NDX from "../abi/NDX.json"
import LP from "../abi/LP.json"
import IYokaiPair from "../abi/IYokaiPair.json"
import { getMarketPrice } from '../utils/token'
import { Contract, Provider, setMulticallAddress } from 'ethers-multicall'
import { timestamp } from '../utils/time'
import UseWallet from '../utils/wallet'

async function approveMaster(lpAddress, amount) {
    // console.log('approveMaster 111111', amount)
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const lp = new ethers.Contract(lpAddress, LP.abi, signer);
    // console.log('approveMaster', lpAddress, account, amount)
    let tx = await lp.approve(CONFIG.MasterGardener, ethers.utils.parseEther(amount.toString()))
    await tx.wait()
}

// function deposit(uint256 _pid, uint256 _amount, address _ref) public nonReentrant {
async function depositPool(pid, amount) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer)
    // console.log('depositPool', Number(ethers.utils.parseEther(amount.toString())), BigInt(amount * 10 ** 18))
    let tx = await masterGardener.deposit(pid, ethers.utils.parseEther(amount.toString()), ethers.constants.AddressZero)
    // let tx = await masterGardener.deposit(pid, BigInt(amount * 10 ** 18), ethers.constants.AddressZero)
    await tx.wait()
}

async function poolInfo() {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer)

    let res = await masterGardener.methods.poolLength()
    // console.log('poolLength  ', res)
}

async function getLPAmount(account, lpAddress) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const lp = new ethers.Contract(lpAddress, LP.abi, signer)
    let res = await lp.balanceOf(account)
    // console.log('getLPAmount', res.toString(), ethers.utils.formatEther(res))

    return ethers.utils.formatEther(res)
}

async function pendingReward(account, pid) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer);
    let res = await masterGardener.pendingReward(pid, account)
    return res
}

function getMasterGardenerContract() {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    return new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer);
}

async function getPoolUserInfo(pid, account) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer

    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer);
    let res = await masterGardener.userInfo(pid, account)
    // console.log('getUserInfo', res)
    return res
}

async function claimReward(pid) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer

    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer)
    let res = await masterGardener.claimReward(pid)
    // console.log('claimReward', res)
}

async function withdrawLP(pid, amount) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer)
    let tx = await masterGardener.withdraw(pid, ethers.utils.parseEther(amount.toString()), ethers.constants.AddressZero)
    await tx.wait()
}

async function getGovToken() {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer)
    return await masterGardener.govToken()
}

async function getMasterAllowAllowance(lpAddress, account) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const lpContract = new ethers.Contract(lpAddress, LP.abi, signer)
    let allowance = await lpContract.allowance(account, CONFIG.MasterGardener)
    // console.log('getMasterAllowAllowance', allowance)
    return allowance
}

async function getNDXAmount(account) {
    // const { walletGlobal } = UseWallet()
    // let provider = walletGlobal.provider
    // const ndxContract = new Contract(CONFIG.NDXAddress, NDX.abi)
    // const multiCall = new Provider(provider, CONFIG.ChainId)
    // await multiCall.init() // Only required when `ChainId` is not provided in the `Provider` constructor
    // let calls = [ndxContract.balanceOf(account), ndxContract.lockOf(account), ndxContract.canUnlockAmount(account)]
    // let [inWallet, locked, unlocked] = await multiCall.all(calls)
    // console.log('getNDXAmount', inWallet, locked, unlocked)

    const { walletGlobal } = UseWallet()
    const signer = walletGlobal.signer
    const ndxContract = new ethers.Contract(CONFIG.NDXAddress, NDX.abi, signer)
    let inWallet = await ndxContract.balanceOf(account)
    let locked = await ndxContract.lockOf(account)
    let unlocked = await ndxContract.canUnlockAmount(account)

    return { inWallet, locked, unlocked }
}

async function getToHarvest(farms, account) {
    const { walletGlobal } = UseWallet()
    if (walletGlobal.wallet === "WalletConnect") {
        let toHarvest = Number(0)
        const master = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, walletGlobal.signer)
        for (let i = 0; i < farms.length; i++) {
            let pending = await master.pendingReward(farms[i].pid, account)
            toHarvest += Number(pending)
        }
        return ethers.utils.formatEther(toHarvest.toString())
    }


    setMulticallAddress(CONFIG.ChainId, CONFIG.MultiCallAddress)
    const multiCall = new Provider(walletGlobal.provider, CONFIG.ChainId)
    await multiCall.init()
    let calls = []
    const master = new Contract(CONFIG.MasterGardener, MasterGardener.abi)
    for (let i = 0; i < farms.length; i++) {
        calls.push(master.pendingReward(farms[i].pid, account))
    }
    let toHarvest = Number((await multiCall.all(calls)).reduce((sum, a) => sum + Number(a), 0))
    return ethers.utils.formatEther(toHarvest.toString())
}

async function getPoolInfo(pid) {
    const { walletGlobal } = UseWallet()
    if (walletGlobal.wallet === "WalletConnect") {
        let { apr, poolLiquidityUsd, multiplier, emissionRate, ndxPriceUsd } = await getPoolInfoWithoutMulticall(pid)
        return { apr, poolLiquidityUsd, multiplier, emissionRate, ndxPriceUsd }
    }

    let provider = walletGlobal.provider
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, provider)
    let pool = await masterGardener.poolInfo(pid)
    // multiplier
    let rewardMultiplier = await masterGardener.getMultiplier(timestamp(), timestamp() + 1)
    rewardMultiplier = Number(rewardMultiplier)
    setMulticallAddress(CONFIG.ChainId, CONFIG.MultiCallAddress)
    const multiCall = new Provider(provider, CONFIG.ChainId)
    await multiCall.init() // Only required when `ChainId` is not provided in the `Provider` constructor
    const pair = new Contract(pool.lpToken, IYokaiPair)
    let calls = [pair.balanceOf(CONFIG.MasterGardener), pair.totalSupply(), pair.getReserves()]
    let [lpStaked, lpTotalSupply, [reserve0, reserve1]] = await multiCall.all(calls)

    let pairToken = CONFIG.Farms[pid].pair.split("-")[1]
    let pairTokenPrice = await getMarketPrice(pairToken)
    let poolLiquidityUsd = (lpStaked / lpTotalSupply) * (Number(reserve1) / 10 ** 18 * Number(pairTokenPrice)) * 2
    // allocation point
    let multiplier = Number(pool.allocPoint / 100)
    let poolWeight = Number(pool.allocPoint / 1000)
    // APR
    let totalSupply = 100000000
    let farmPercentage = 0.6
    let NDX_PER_YEAR = BigNumber(totalSupply * farmPercentage)
    let ndxPriceUsd = reserve1 / reserve0 * pairTokenPrice
    const yearlyRewardAllocation = NDX_PER_YEAR.times(poolWeight)
    const apr = yearlyRewardAllocation.times(ndxPriceUsd).div(poolLiquidityUsd).times(100)
    // emission rate
    let emissionRate = Number(poolWeight) * (rewardMultiplier) * 60 * 0.1
    // console.log({ apr, poolLiquidityUsd, multiplier, emissionRate })

    return { apr, poolLiquidityUsd, multiplier, emissionRate, ndxPriceUsd }
}

async function getPoolInfoWithoutMulticall(pid) {
    const { walletGlobal } = UseWallet()
    // let provider = walletGlobal.provider
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, walletGlobal.signer)
    let pool = await masterGardener.poolInfo(pid)
    // multiplier
    let rewardMultiplier = await masterGardener.getMultiplier(timestamp().toString(), (timestamp() + 1).toString())
    rewardMultiplier = Number(rewardMultiplier)
    const pair = new ethers.Contract(pool.lpToken, IYokaiPair, walletGlobal.signer)
    let lpStaked = await pair.balanceOf(CONFIG.MasterGardener)
    let lpTotalSupply = await pair.totalSupply()
    let [reserve0, reserve1] = await pair.getReserves()
    let pairToken = CONFIG.Farms[pid].pair.split("-")[1]
    let pairTokenPrice = await getMarketPrice(pairToken)
    let poolLiquidityUsd = (lpStaked / lpTotalSupply) * (Number(reserve1) / 10 ** 18 * Number(pairTokenPrice)) * 2
    // allocation point
    let multiplier = Number(pool.allocPoint / 100)
    let poolWeight = Number(pool.allocPoint / 1000)
    // APR
    let totalSupply = 100000000
    let farmPercentage = 0.6
    let NDX_PER_YEAR = BigNumber(totalSupply * farmPercentage)
    let ndxPriceUsd = reserve1 / reserve0 * pairTokenPrice
    const yearlyRewardAllocation = NDX_PER_YEAR.times(poolWeight)
    const apr = yearlyRewardAllocation.times(ndxPriceUsd).div(poolLiquidityUsd).times(100)
    // emission rate
    let emissionRate = Number(poolWeight) * (rewardMultiplier) * 60 * 0.1
    // console.log({ apr, poolLiquidityUsd, multiplier, emissionRate })

    return { apr, poolLiquidityUsd, multiplier, emissionRate, ndxPriceUsd }
}

async function claimRewards(pids) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer)
    let tx = await masterGardener.claimRewards(pids)
    await tx.wait()
}

async function withdrawFeePercent(pid) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer)
    let percent = await masterGardener.withdrawFeePercent(pid)
    return percent
}

async function getLockPercentage() {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const masterGardener = new ethers.Contract(CONFIG.MasterGardener, MasterGardener.abi, signer)
    let percent = await masterGardener.getLockPercentage(pid)
    return percent
}

async function unlock() {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const ndxContract = new ethers.Contract(CONFIG.NDXAddress, NDX.abi, signer)
    let tx = await ndxContract.unlock()
    await tx.wait()
}

export {
    approveMaster,
    depositPool,
    pendingReward,
    getPoolUserInfo,
    claimRewards,
    claimReward,
    withdrawLP,
    getGovToken,
    getMasterAllowAllowance,
    poolInfo,
    getLPAmount,
    getNDXAmount,
    getMasterGardenerContract,
    getPoolInfo,
    withdrawFeePercent,
    getLockPercentage,
    unlock,
    getToHarvest,
}
<template>
  <div v-if="account !== 'Connect Wallet'">
    <div class="md:mt-8 mt-10">
      <h2 class="text-center">
        <span class="bg-gray-900 text-gray-400 font-semibold"> Balance </span>
      </h2>

      <div class="flex text-white w-full text-xs">
        <span class="w-1/3">Asset</span>
        <span class="w-1/3 text-center">Wallet</span>
        <span class="w-1/3 text-right">Account</span>
      </div>
      <div class="flex w-full text-xs text-gray-400 mt-2">
        <span class="w-1/3"> {{ baseToken }}</span>
        <span class="w-1/3 text-center">{{ baseAmountWallet }}</span>
        <span class="w-1/3 text-right"> {{ baseAmount }}</span>
      </div>
      <div class="flex w-full text-xs text-gray-400 mt-2">
        <span class="w-1/3"> {{ quoteToken }}</span>
        <span class="w-1/3 text-center">{{ quoteAmountWallet }}</span>
        <span class="w-1/3 text-right"> {{ quoteAmount }}</span>
      </div>
    </div>
  </div>
  <div v-else class="text-gray-50 bg-gray-900 text-center py-3 flex flex-col mt-10 justify-center items-center">
    <div class="mb-1 h-6 w-6 text-th-primary">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"></path>
      </svg>
    </div>
    Connect Wallet
    <div>
      <button class="bg-gray-800 px-20 py-2 mt-3 hover:bg-gray-600" @click="onConnectWallet">Connect</button>
    </div>
  </div>
  <WalletModal :showWallet="showModal" @close="showModal = false" />
</template>

<script>
import WalletModal from './WalletModal.vue'
import { getBalance, getVaultBalance } from '../contract/contract'
import { mapGetters } from 'vuex'

export default {
  components: {
    WalletModal,
  },
  data() {
    return {
      baseAmount: null,
      quoteAmount: null,
      baseAmountWallet: null,
      quoteAmountWallet: null,
      baseToken: '',
      quoteToken: '',
      showModal: false,
      isOpen: false,
      refreshCls: 'ml-2 h-5 w-5 cursor-pointer',
      refreshCls1: 'ml-2 h-5 w-5 cursor-pointer',
    }
  },
  computed: {
    ...mapGetters(['market', 'account', 'chainId']),
  },
  watch: {
    market: async function (newVal, _) {
      let arr = newVal.split('-')
      this.baseToken = arr[0]
      this.quoteToken = arr[1]
      await this.loadBalance()
    },
    account: async function (newVal, oldVal) {
      let arr = this.market.split('-')
      this.baseToken = arr[0]
      this.quoteToken = arr[1]
      await this.loadBalance()
    },
    chainId: async function (newVal, oldVal) {
      let arr = this.market.split('-')
      this.baseToken = arr[0]
      this.quoteToken = arr[1]
      await this.loadBalance()
    },
  },
  methods: {
    async loadBalance() {
      if (this.account.includes('0x')) {
        // this.baseAmount = this.$format(await getvaultbalance(this.basetoken, this.account), 4)
        // this.quoteAmount = this.$format(await getvaultbalance(this.quotetoken, this.account), 4)
        this.baseAmount = 0
        this.quoteAmount = 0
        this.baseAmountWallet = this.$format(await getBalance(this.baseToken, this.account), 4)
        this.quoteAmountWallet = this.$format(await getBalance(this.quoteToken, this.account), 4)

      }
    },
    onConnectWallet() {
      this.showModal = true
    },
    async tick() {
      await this.loadBalance()
    },
  },
  async mounted() {
    this.timer = setInterval(this.tick, 2000)
  },
  beforeUnmount() {
    clearTimeout(this.timer)
  },
}
</script>

<style scoped>
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgb(75 85 99);
  line-height: 0.1em;
  margin: 10px 0 20px;
  font: bold;
  font-size: 15px;
}

h2 span {
  padding: 0 10px;
}
</style>

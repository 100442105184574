<template>
  <div id="tv_chart_container" class="w-full h-[50vh]" v-show="show"></div>
  <div class="w-full h-[50vh]" v-show="!show"></div>
</template>

<script>
import Datafeed from '../trading-view/datafeed'
import { mapGetters } from 'vuex'
const RED = '#fa3c58'
const GREEN = '#0ecc83'
export const DEFAULT_PERIOD = '4h'

const chartStyleOverrides = ['candleStyle', 'hollowCandleStyle', 'haStyle'].reduce((acc, cv) => {
  acc[`mainSeriesProperties.${cv}.drawWick`] = true
  acc[`mainSeriesProperties.${cv}.drawBorder`] = false
  acc[`mainSeriesProperties.${cv}.upColor`] = GREEN
  acc[`mainSeriesProperties.${cv}.downColor`] = RED
  acc[`mainSeriesProperties.${cv}.wickUpColor`] = GREEN
  acc[`mainSeriesProperties.${cv}.wickDownColor`] = RED
  acc[`mainSeriesProperties.${cv}.borderUpColor`] = GREEN
  acc[`mainSeriesProperties.${cv}.borderDownColor`] = RED
  return acc
}, {})

const chartOverrides = {
  'paneProperties.background': '#0E1421',
  'paneProperties.backgroundGradientStartColor': '#0E1421',
  'paneProperties.backgroundGradientEndColor': '#0E1421',
  'paneProperties.backgroundType': 'solid',
  'paneProperties.vertGridProperties.color': 'rgba(35, 38, 59, 1)',
  'paneProperties.vertGridProperties.style': 2,
  'paneProperties.horzGridProperties.color': 'rgba(35, 38, 59, 1)',
  'paneProperties.horzGridProperties.style': 2,
  'mainSeriesProperties.priceLineColor': '#3a3e5e',
  'scalesProperties.textColor': '#fff',
  'scalesProperties.lineColor': '#0E1421',
  ...chartStyleOverrides,
}

const disabledFeatures = [
  'volume_force_overlay',
  'show_logo_on_all_charts',
  'caption_buttons_text_if_possible',
  'create_volume_indicator_by_default',
  'header_compare',
  'compare_symbol',
  'display_market_status',
  'header_interval_dialog_button',
  'show_interval_dialog_on_key_press',
  'header_symbol_search',
  'popup_hints',
  'header_in_fullscreen_mode',
  'use_localstorage_for_settings',
  'right_bar_stays_on_scroll',
  'symbol_info',
]
const enabledFeatures = ['side_toolbar_in_fullscreen_mode', 'header_in_fullscreen_mode', 'hide_resolution_in_legend', 'items_favoriting', 'hide_left_toolbar_by_default']

export default {
  data() {
    return {
      show: true,
    }
  },
  computed: {
    ...mapGetters(['market', 'account']),
  },
  watch: {
    market: async function (newVal, _) {
      console.log(newVal)
      if (newVal == 'CKB-USDT' || newVal == 'CKB-USDC') {
        this.show = true
        let widget = window.tvWidget
        widget.setSymbol('CKB/USD', widget.activeChart().resolution(), () => {})
      } else if (newVal == 'BTC-USDT') {
        this.show = true
        let widget = window.tvWidget
        widget.setSymbol('BTC/USD', widget.activeChart().resolution(), () => {})
      } else {
        this.show = false
      }
    },
  },
  mounted() {
    const script = document.createElement('script')
    script.type = 'text/script'
    script.src = '%PUBLIC_URL%/charting_library/charting_library.js'
    document.head.appendChild(script)

    window.tvWidget = new window.TradingView.widget({
      theme: 'Dark',
      locale: 'en',
      symbol: 'CKB/USD', // default symbol
      interval: '240', // default interval
      fullscreen: false, // displays the chart in the fullscreen mode
      autosize: true,
      header_widget_dom_node: false,
      enabled_features: enabledFeatures,
      disabled_features: disabledFeatures,
      favorites: {
        intervals: ['5', '15', '60', '240', '1D'],
      },
      container: 'tv_chart_container',
      datafeed: Datafeed,
      library_path: '/charting_library/',
      custom_css_url: '/tradingview-chart.css',
      loading_screen: {
        backgroundColor: '#101012',
      },
      overrides: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...chartOverrides,
      },
    })
  },
}
</script>

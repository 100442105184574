<template>
  <div class="flex justify-center items-center -z-10 bg-transparent h-full">
    <Menu v-slot="{ open }" as="div" class="relative inline-block text-left z-10 bg-transparent my-auto w-full">
      <div class="mx-auto">
        <MenuButton class="mx-auto inline-flex w-full justify-center rounded-md py-2 font-bold text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <div class="flex flex-row justify-center space-x-4 items-center">
            <img class="h-[26px]" :src="tokenLogoURI" alt="" />
            <span class="ml-1 text-xl"> {{ market }}</span>
            <ChevronDownIcon :class="[open ? 'rotate-180' : 'rotate-360', 'ml-2 -mr-1 h-5 w-5 transition duration-200']" aria-hidden="true" />
          </div>
        </MenuButton>
      </div>

      <transition v-show="open" enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
        <MenuItems class="absolute w-full mt-2 divide-y bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class=" ">
            <div class="h-12 bg-transparent items-center flex text-gray-200 text-sm border-b border-gray-700 my-2 pb-2">
              <ul class="flex justify-center items-center mx-auto space-x-4 flex-wrap w-full gap-1">
                <li v-for="(token, index) in quoteList" :key="index" class="mr-2">
                  <a href="javascript:void(0)" :class="[quoteToken === token ? 'border-b-2 text-bold text-gray-300 border-blue-600' : 'bg-transparent', 'inline-block rounded-t-l hover:text-gray-300 hover:border-blue-600']" @click="toggleTabs(token)">{{ token }}</a>
                </li>
              </ul>
            </div>

            <MenuItem v-for="(item, index) in marketList[quoteToken]" :key="index" v-slot="{ active }" class="flex flex-row justify-center">
              <button @click="selectMarket(item.market)" :class="[active ? 'bg-gray-500 text-white' : 'text-gray-900', 'group flex w-full items-center text-center px-2 py-2']">
                <img class="h-6 mr-3" :src="getTokenLogo(item.market.split('-')[0])" alt="token logo" />
                <span class="text-white">{{ item.market }}</span>
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { mapActions, mapGetters } from 'vuex'
import { getTokenCfg } from '../utils/token'
import { getMarketList } from '../config/market_list'
import { tokenLogos } from '../config'
import store from '../store/index'

export default {
  data() {
    return {
      btnTxt: 'Connect Wallet',
      market: '',
      marketList: ['BTC-USDC'],
      tokenLogoURI: '',
      openTab: 1,
      quoteToken: '',
      tokeList: [],
      open: false,
    }
  },
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ChevronDownIcon,
  },
  computed: {
    ...mapGetters(['chain', 'chainId']),
  },
  watch: {
    chain: async function (chain, _) {
      this.loadData()
    },
  },

  methods: {
    ...mapActions(['updateMarket']),
    loadData() {
      this.marketList = getMarketList(this.chainId)
      // console.log('market list 11111 ', this.marketList)
      this.quoteList = Object.keys(this.marketList)
      this.quoteToken = Object.keys(this.marketList)[0]
      const defaultMarket = this.marketList[this.quoteToken][0].market
      this.selectMarket(defaultMarket)
    },
    getTokenLogo(symbol) {
      return tokenLogos[symbol]
    },
    selectMarket(market) {
      this.dropdownPopoverShow = false
      this.market = market
      this.updateMarket(market)
      let symbol = market.split('-')[0]
      this.tokenLogoURI = this.getTokenLogo(symbol)
    },
    toggleTabs(token) {
      this.quoteToken = token
    },
    update() {
      this.updateMarket(this.market)
      // console.log(getTokenCfg(this.market.split('-')[0]))
      let symbol = market.split('-')[0]
      this.tokenLogoURI = this.getTokenLogo(symbol)
      this.quoteList = Object.keys(this.marketList)
      this.quoteToken = Object.keys(this.marketList)[0]
    },
  },
  async mounted() {
    if (this.chainId == 0) {
      return
    }
    this.loadData()
  },
  beforeUnmount() {
    this.updateMarket('')
  },
}
</script>

import { createStore } from 'vuex'
import farm from './farm'
import contract from './contract'
import bridge from './bridge'
import { getVaultBalance, getBalance as getDexBalance } from '../contract/contract'
import { getSupportedChain } from '../config'

export default createStore({
  state: {
    market: '',
    account: 'Connect Wallet',
    chain: getSupportedChain()[0],
    marketVolume: '',
    signer: null,
    marketInfo: {},
  },
  actions: {
    async fetchCrypto({ commit }) {
      try {
        // const response = await axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=true')
        // console.log(response)
        // commit('setCrypto', response.data)
      } catch (error) {
        console.error(error)
      }
    },
    async updateMarket({ commit }, payload) {
      commit('setMarket', payload)
    },
    async updateAccount({ commit }, payload) {
      commit('setAccount', payload)
    },
    async updateMarketVolume({ commit }, payload) {
      commit('setMarketVolume', payload)
    },
    async updateBalance({ commit, state }, address) {
      // let account = await getVaultBalance(address, state.account)
      // let wallet = await getDexBalance(address, state.account)
      // commit('setBalance', { address: address, wallet: wallet, account: account })
    },
    async updateChainId({ commit }, payload) {
      commit('setChain', payload)
    },
  },
  mutations: {
    setAccount: (state, payload) => {
      state.account = payload
    },
    setMarket: (state, payload) => {
      state.market = payload
    },
    setMarketVolume: (state, payload) => {
      state.marketVolume = payload
    },
    setMarketInfo: (state, payload) => {
      state.marketInfo[payload.id] = payload
    },
    setChain: (state, payload) => {
      state.chain = payload
    },
  },
  getters: {
    market: (state) => state.market,
    account: (state) => state.account,
    walletBalance: (state) => state.walletBalance,
    marketVolume: (state) => state.marketVolume,
    lockedBalance: (state) => state.lockedBalance,
    marketInfo: (state) => state.marketInfo,
    chain: (state) => state.chain,
    chainId: (state) => state.chain.ChainId,
  },
  modules: {
    farm,
    contract,
    bridge,
  },
})

import { http, createConfig } from '@wagmi/vue';
import { mainnet, sepolia, baseSepolia } from '@wagmi/vue/chains';
import { injected, walletConnect, coinbaseWallet } from '@wagmi/vue/connectors';

const projectId = '3fbb6bba6f1de962d911bb5b5c9dba88';

export const config = createConfig({
  chains: [sepolia, mainnet, baseSepolia],
  connectors: [coinbaseWallet()],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
    [baseSepolia.id]: http(),
  },
});
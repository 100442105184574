import BigNumber from 'bignumber.js'
import FEE_TIRE from '../config/fee_tire'

export const calculateTrade = ({ orderType, side, price, amount, ndxAmount, asMakerFeeRate, asTakerFeeRate, amountDecimals, priceDecimals }) => {
  let tradeFee, subtotal, totalBaseTokens

  const isMakerFee = orderType === 'limit'
  const discount = getDiscountRate(ndxAmount)
  console.log('discount', Number(discount))
  console.log('amount', Number(amount))
  console.log('price', Number(price))

  const feeRate = orderType === 'market' ? asTakerFeeRate : asTakerFeeRate
  if (orderType === 'market' && side === 'buy') {
    subtotal = amount
    // totalBaseTokens = amount.div(price).dp(amountDecimals, BigNumber.ROUND_DOWN)
  } else {
    subtotal = amount.multipliedBy(price).dp(priceDecimals, BigNumber.ROUND_DOWN)
    // totalBaseTokens = amount
  }
  console.log('subtotal', Number(subtotal))
  const estimatedPrice = orderType === 'market' ? price : new BigNumber(0)

  tradeFee = subtotal.multipliedBy(feeRate)
  console.log('tradeFee', Number(tradeFee))
  const tradeFeeAfterDiscount = tradeFee.multipliedBy(discount)
  console.log('tradeFeeAfterDiscount', Number(tradeFeeAfterDiscount))
  const feeRateAfterDiscount = feeRate.multipliedBy(discount)
  let totalQuoteTokens
  if (side === 'buy') {
    totalQuoteTokens = subtotal.plus(tradeFeeAfterDiscount).dp(priceDecimals, BigNumber.ROUND_UP)
  } else {
    totalQuoteTokens = subtotal.minus(tradeFeeAfterDiscount).dp(priceDecimals, BigNumber.ROUND_UP)
  }
  totalQuoteTokens = BigNumber.max(totalQuoteTokens, new BigNumber('0'))

  return {
    estimatedPrice,
    discount,
    totalBaseTokens,
    tradeFeeAfterDiscount,
    feeRateAfterDiscount,
    tradeFee,
    feeRate,
    isMakerFee,
    subtotal,
    totalQuoteTokens,
  }
}

export const feeRateAfterDiscount = (feeRate, ndxAmount) => {
  const discount = getDiscountRate(ndxAmount)
  return feeRate.multipliedBy(discount)
}

const getDiscountRate = (ndxAmount) => {
  for (let tire of FEE_TIRE) {
    const limit = new BigNumber(tire[0])
    const discountRate = new BigNumber(tire[1])

    if (limit.eq(-1)) {
      return discountRate
    } else if (ndxAmount.lte(limit)) {
      return discountRate
    }
  }

  return new BigNumber(1)
}

const FarmConf = {
  mainnet: [
    {
      pid: 0,
      pair: 'NDX-CKB',
      lpAddress: '0x2827361b6efe47dede272fd80dc3bd6c296a1986',
      link: 'https://www.yokaiswap.com/liquidity/add/0x571cBe5175CF64f73ccC7301e70d04a1b494Ea3e/0xC296F806D15e97243A08334256C705bA5C5754CD',
    },
    {
      pid: 1,
      pair: 'NDX-YOK',
      lpAddress: '0xadaa33f631ce9ff87f8f50e42a819fb7845a07b2',
      link: 'https://www.yokaiswap.com/liquidity/add/0x571cBe5175CF64f73ccC7301e70d04a1b494Ea3e/0x885fb612947ccF1C7611894Bd828D388b046fc24',
    },
  ],
  testnet: [
    {
      pid: 0,
      pair: 'NDX-CKB',
      lpAddress: '0x2827361b6efe47dede272fd80dc3bd6c296a1986',
      link: 'https://www.yokaiswap.com/liquidity/add/0x571cBe5175CF64f73ccC7301e70d04a1b494Ea3e/0xC296F806D15e97243A08334256C705bA5C5754CD',
    },
    {
      pid: 1,
      pair: 'NDX-YOK',
      lpAddress: '0xadaa33f631ce9ff87f8f50e42a819fb7845a07b2',
      link: 'https://www.yokaiswap.com/liquidity/add/0x571cBe5175CF64f73ccC7301e70d04a1b494Ea3e/0x885fb612947ccF1C7611894Bd828D388b046fc24',
    },
  ],
}

export default FarmConf

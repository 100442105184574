<template>
  <li @click="onConnect">
    <a href="#"
      class="flex items-center justify-start p-3 text-base font-bold text-gray-900 bg-gray-600 rounded hover:bg-gray-100 group hover:shadow">
      <div class="h-6">
        <img class="h-6" :src="joyId" alt="" />
      </div>
      <span class="flex-1 ml-3 whitespace-nowrap">JoyID</span>
    </a>
  </li>
</template>
<script>
import { providers } from 'ethers'
import { connect, initConfig } from '@joyid/evm'
import store from '../../store'
import UseWallet from '../../utils/wallet'
import { provider as joyIdProvider } from '../../utils/joyid'
const { walletGlobal } = UseWallet()
import NETWORK from '../../config/network'

import joyId from '../../assets/joyid.svg'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      joyId: joyId,
    }
  },
  methods: {
    async onConnect() {
      try {
        const address = await connect()
        store.commit('setAccount', address)
        walletGlobal.wallet = 'joy.id'
        walletGlobal.signer = joyIdProvider()
        walletGlobal.account = address
        walletGlobal.provider = new providers.JsonRpcBatchProvider(this.rpc, {
          name: this.chainName,
          chainId: this.chainId,
        })
        this.$emit('close')
      } catch (error) {
        console.log(error)
      }
    },
  },
  computed: {
    ...mapGetters(['account', 'chainId', 'chain']),
  },
  mounted() {
    // console.log('....joyId init', this.chainId)
    console.log(NETWORK[this.chainId].rpcUrls[0])
    console.log(NETWORK[this.chainId].chainName)
    this.rpc = NETWORK[this.chainId].rpcUrls[0]
    this.chainName = NETWORK[this.chainId].chainName
    initConfig({
      name: 'JoyID EVM',
      logo: 'https://fav.farm/🆔',
      joyidAppURL: this.chain.Mainnet ? 'https://app.joy.id' : 'https://poc.joyid.dev',
      rpcURL: NETWORK[this.chainId].rpcUrls[0],
      network: {
        name: NETWORK[this.chainId].chainName,
        chainId: this.chainId,
      },
    })
  },
}
</script>
<style></style>

<template>
  <div v-show="showBtn" class="cursor-pointer hidden md:block">
    <span class="outline-none p-1 rounded-sm border border-gray-600 mr-4 text-gray-300">
      <button class="hover:bg-gray-500 px-0.5 hover:rounded-sm" @click="showDeposit = true">Deposit</button>
      /
      <button class="hover:bg-gray-500 px-0.5 hover:rounded-sm" @click="showWithdraw = true">Withdraw</button>
    </span>
  </div>

  <DialogDeposit :isOpen="showDeposit" @close="showDeposit = false" :depositToken="depositToken" ref="deposit" />
  <DialogWithdraw :isOpen="showWithdraw" @close="showWithdraw = false" :withdrawToken="withdrawToken" ref="withdraw" />
</template>
<script>
import DialogDeposit from './modal/DialogDeposit.vue'
import DialogWithdraw from './modal/DialogWithdraw.vue'
export default {
  components: {
    DialogDeposit,
    DialogWithdraw,
  },
  data() {
    return {
      showBtn: false,
      showDeposit: false,
      depositToken: '',
      showWithdraw: false,
      withdrawToken: '',
    }
  },
  watch: {
    $route(to, _) {
      this.showBtn = false
      if (to.fullPath === '/trade') {
        this.showBtn = true
      }
    },
  },
}
</script>
<style></style>

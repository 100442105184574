import NETWORK from '..'
import tokenListMainnet from './tokenList_Godwoken.json'
import tokenListTestnet from './tokenList_GodwokenTestnet.json'
import tokenListBaseGoerli from './tokenList_BaseGoerli.json'
import tokenListBaseSepolia from './tokenList_BaseSepolia.json'
import fantomTestnet from './tokenList_FantomTestnet.json'
import fantomSonic from './tokenList_FantomSonic.json'
import polygonMumbai from './tokenList_Mumbai.json'
import avalancheFuji from './tokenList_AvalancheFuji.json'
import modeTestnet from './tokenList_ModeTestnet.json'
import modeMainnet from './tokenList_ModeMainnet.json'
import anvilTestnet from './tokenList_Anvil.json'
import Base from './tokenList_Base.json'

const tokenList = {
  71402: tokenListMainnet,
  71401: tokenListTestnet,
  84531: tokenListBaseGoerli,
  84532: tokenListBaseSepolia,
  4002: fantomTestnet,
  64240: fantomSonic,
  80001: polygonMumbai,
  43113: avalancheFuji,
  919: modeTestnet,
  34443: modeMainnet,
  31337: anvilTestnet,
  8453: Base,
}

export function getTokenList(chainId) {
  // console.log('getTokenList', chainId)
  if (tokenList[chainId]) {
    return tokenList[chainId]
  }
  return tokenListMainnet
}

export default tokenList[NETWORK]

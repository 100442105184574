import { ethers } from 'ethers'
import aibNFT from '../abi/NFT.json'
import abiERC20 from '../abi/IERC20.json'
import { approveJoyId } from '../utils/joyid'
import UseWallet from '../utils/wallet'
const { walletGlobal } = UseWallet()

import NETWORK from '../config/network'
import store from '../store'

async function getNFTMintPrice(nftAddress) {
  let provider = new ethers.providers.JsonRpcProvider('https://v1.mainnet.godwoken.io/rpc')
  const nftContract = new ethers.Contract(nftAddress, aibNFT, provider)
  let price = await nftContract.price()
  return price
}

async function getAllowance(tokenAddress, owner, spender) {
  const tokenContract = new ethers.Contract(tokenAddress, abiERC20, walletGlobal.signer)
  return tokenContract.allowance(owner, spender)
}

async function approveNFTContract(nftAddress, ndxAddress, amount) {
  // let price = await getNFTMintPrice(nftAddress)
  // let amount = price
  // console.log('approve', nftAddress, ndxAddress, amount, amount.toString())
  if (walletGlobal.wallet == 'joy.id') {
    await approveJoyId(ndxAddress, nftAddress, walletGlobal.account, amount)
  } else {
    let signer = walletGlobal.signer
    const tokenContract = new ethers.Contract(ndxAddress, abiERC20, signer)
    let tx = await tokenContract.approve(nftAddress, amount)
    await tx.wait()
  }
}

async function mintNFTWithNDX(nftAddress) {
  let signer = walletGlobal.signer
  const nftContract = new ethers.Contract(nftAddress, aibNFT, signer)
  let tx = await nftContract.mintWithNDX()
  await tx.wait()
}

async function currentId(nftAddress) {
  let provider = new ethers.providers.JsonRpcProvider('https://v1.mainnet.godwoken.io/rpc')
  const nftContract = new ethers.Contract(nftAddress, aibNFT, provider)
  const id = await nftContract.currentId()
  return id.toString()
}

export { approveNFTContract, mintNFTWithNDX, getNFTMintPrice, getAllowance, currentId }

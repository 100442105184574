import { ethers } from 'ethers'

var walletGlobal = {
  signer: null,
  provider: null,
  wallet: '',
  account: '',
  chainId: 71402,
}

async function connectWithEther() {
  const { ethereum } = window
  if (!ethereum) {
    alert('Must connect to MetaMask!')
    return
  }

  const provider = new ethers.providers.Web3Provider(ethereum)
  const address = (await ethereum.request({ method: 'eth_requestAccounts' }))[0]
  let signer = provider.getSigner()
  walletGlobal.signer = signer
  walletGlobal.provider = provider
  walletGlobal.wallet = 'MetaMask'

  return address
}

export async function changeNetwork() {
  const { ethereum } = window
  const provider = new ethers.providers.Web3Provider(ethereum)
  let signer = provider.getSigner()
  walletGlobal.signer = signer
  walletGlobal.provider = provider
}

export default function UseWallet() {
  return {
    walletGlobal,
    connectWithEther,
  }
}

const state = {
    tokenContracts: [],
    vaultContracts: [],
    chainContract: {},
}

const mutations = {
    // base contract
    updateTokenContract(state, payload) {
        console.log('updateTokenContract', payload)
        const { address, contract } = payload
        state.tokenContracts[address] = contract
    },
    updateVaultContract(state, payload) {
        console.log('updateVaultContract', payload)
        const { address, contract } = payload
        state.tokenContracts[address] = contract
    },
    // other contract
    updateContract(state, payload) {
        console.log('updateVaultContract', payload)
        const { chainId, address, contract } = payload
        let contractMap = state.chainContract[chainId]
        contractMap[address] = contract
    },
}

const actions = {

}

const getters = {
    tokenContracts: (state) => state.tokenContracts,
    vaultContracts: (state) => state.tokenContracts,
    chainContract: (state) => state.chainContract,
}

export default {
    state,
    mutations,
    actions,
    getters,
}

import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import router from './router';
import store from './store';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import 'mosha-vue-toastify/dist/style.css'
import { formatNumber } from './utils/token'

import { VueQueryPlugin } from '@tanstack/vue-query';
import { WagmiPlugin } from '@wagmi/vue';

import { config } from "./utils/wagmi"


let app = createApp(App)

app.config.globalProperties.$format = formatNumber

app.use(FloatingVue)
  .use(WagmiPlugin, { config })
  .use(VueQueryPlugin, {})
  .use(store)
  .use(router)
  .mount(document.body)

// import { createApp } from 'vue'
// import App from './App.vue'
// createApp(App).mount(document.body)

<template>
  <li @click="onConnect">
    <a href="#" class="flex items-center justify-center p-3 text-base font-bold text-gray-900 bg-gray-600 rounded hover:bg-gray-100 group hover:shadow">
      <img class="h-6" :src="metaMask" alt="" />
      <span class="flex-1 ml-3 whitespace-nowrap">MetaMask</span>
    </a>
  </li>
</template>
<script>
import { providers } from 'ethers'
import store from '../../store'
import UseWallet from '../../utils/wallet'
const { walletGlobal, connectWithEther } = UseWallet()
import NETWORK from '../../config/network'
import metaMask from '../../assets/metamask.svg'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      metaMask: metaMask,
    }
  },
  methods: {
    async onConnect() {
      try {
        let address = await connectWithEther()
        store.commit('setAccount', address)
      } catch (error) {
        console.error('onConnect', error)
      }
      this.$emit('close')
    },
  },
  computed: {
    ...mapGetters(['account', 'chainId', 'chain']),
  },
  mounted() {},
}
</script>
<style></style>

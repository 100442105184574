<template>
  <div class="w-full">
    <Menu as="div" class="relative inline-block text-left w-full">
      <div>
        <MenuButton class="inline-flex w-full justify-center bg-gray-800 px-4 py-2 text-sm font-medium text-white">
          {{ selectedChain }}

          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#98A1C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mt-0.5 ml-2">
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </MenuButton>
      </div>

      <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
        <MenuItems class="absolute right-0 mt-2 w-full origin-top-right divide-y divide-gray-100 bg-slate-800 shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div class="px-1 py-1">
            <div v-for="(item, index) in chains" :key="index">
              <MenuItem v-slot="{ active }">
                <button @click="onClick(item)" :class="[active ? 'bg-gray-500 text-white' : 'text-white', 'group flex flex-row  justify-start w-full items-center  space-x-5 px-[12rem] py-2 text-sm mx-auto']">
                  <img class="h-[1.5rem] z-10" :src="`${item.Logo}`" alt="logo" />
                  <span> {{ item.ChainName }}</span>
                </button>
              </MenuItem>
            </div>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { getBridgedChain } from '../../config'
import store from '../../store'

const chains = getBridgedChain()
const selectedChain = ref('Receiver Blockchain')

const onClick = (item) => {
  // console.log('selected chain', item)
  selectedChain.value = item.ChainName
  store.commit('updateReceiverChain', item.ChainId)
}
</script>

<template>
  <div class="flex flex-col h-full">
    <div class="overflow-auto bg-gray-900">
      <table class="w-full border-separate">
        <thead class="">
          <tr>
            <th class="w-1/3 text-xs font-medium tracking-wider text-left text-gray-400 uppercase">Price({{ quoteToken }})</th>
            <th class="w-1/3 text-xs font-medium tracking-wider text-center text-gray-400 uppercase">Size({{ baseToken }})</th>
            <th class="w-1/3 text-xs font-medium tracking-wider text-right text-gray-400 uppercase">Time</th>
          </tr>
        </thead>
        <tbody>
          <tr class="" v-for="item in history" :key="item.id">
            <td class="w-1/3 text-xs whitespace-nowrap text-left" v-bind:class="{ 'text-green-400': item.TakerSide === 'buy', 'text-red-500': item.TakerSide === 'sell' }">{{ item.PriceInDecimal }}</td>
            <td class="w-1/3 text-xs text-gray-300 whitespace-nowrap text-center">{{ item.AmountInDecimal }}</td>
            <td class="w-1/3 text-xs text-gray-500 whitespace-nowrap text-right">{{ dateTime(item.CreateTime) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { marketHistory } from '../api'
import moment from 'moment'

export default {
  props: ['baseToken', 'quoteToken'],
  data() {
    return {
      history: null,
    }
  },
  methods: {
    tick() {
      marketHistory({
        marketID: `${this.baseToken}-${this.quoteToken}`,
      }).then((ret) => {
        console.log('==> marketHistory ret:', ret)
        this.history = ret
      })
    },
    dateTime(timestamp) {
      let offset = new Date() + ''
      let timezone = offset.indexOf('GMT')
      let timezoneId = offset.substring(timezone + 3, timezone + 8)
      let orderDate = moment(timestamp * 1000)
        .utcOffset(timezoneId)
        .format('MM-DD HH:mm:ss')
      return orderDate
    },
  },
  mounted() {
    this.timer = setInterval(this.tick, 2000)
  },
  beforeUnmount() {
    clearTimeout(this.timer)
  },
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 6px;
  height: 1px;
  /* background-color: transparent; */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* box-shadow: inset000pxrgba(240, 240, 240, 0.5); */
  background-color: rgba(240, 240, 240, 0.5);
}
</style>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" class="relative z-20">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div class="flex min-h-full items-center justify-center px-4 text-center w-96 mx-auto">
          <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
            <DialogPanel class="w-full max-w-md transform rounded bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                <div class="text-white">Wallet Asset</div>
                <button type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle="crypto-modal" @click="onClose">
                  <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                  </svg>
                </button>
              </DialogTitle>

              <div class="pt-4 pb-6">
                <Dropdown class="my-dropdown-toggle" :options="tokens()" :selected="selectedToken" v-on:updateOption="onDropDownSelect" :placeholder="''"> </Dropdown>
                <div class="text-white mb-7">Amount</div>

                <div class="relative flex items-center">
                  <input ref="depositInput" type="number" placeholder="0" class="w-full absolute block -top-4.5 border sm:text-sm text-gray-50 border-gray-500 text-left bg-gray-800" v-model="selectedToken.input" @input="handleInputChange" />
                  <span class="absolute -top-2.5 right-12 text-blue-400 text-sm cursor-pointer" @click="onMax">Max</span>
                  <span class="absolute -top-2.5 right-2 text-gray-400 text-sm">{{ selectedToken.name }}</span>
                </div>
              </div>

              <div class="flex flex-row justify-between">
                <div class="text-white text-xs">Wallet Balance</div>
                <div class="text-white text-xs">{{ $format(selectedToken.balance, 4) }} {{ selectedToken.name }}</div>
              </div>

              <div v-if="showDeposit" class="mt-10 flex justify-center items-center pb-4">
                <SpinnerButtonCls cls="flex justify-center py-2 w-full bg-gray-500 hover:bg-gray-400 text-gray-50" @click="onDeposit" normalTxt="Deposit" loadingTxt="Depositing" ref="depositButton" />
              </div>
              <div v-else class="mt-10 flex justify-center items-center pb-4">
                <SpinnerButtonCls cls="flex justify-center py-2 w-full bg-gray-500 hover:bg-gray-400 text-gray-50" @click="onApprove" normalTxt="Approve" loadingTxt="Approving" ref="approveButton" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'
import { mapGetters } from 'vuex'
import { getTokenList } from '../../config/token_list'
import Dropdown from './DropDown.vue'
import { getTokenAddress } from '../../utils/token'
import { getBalance, approve, deposit, allowance } from '../../contract/contract'
import SpinnerButtonCls from '../SpinnerButtonCls.vue'
import { showToast } from '../../utils/toast'
import { getTokenCfg } from '../../utils/token'

export default {
  name: 'DialogDeposit',
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Dropdown,
    SpinnerButtonCls,
  },
  computed: {
    ...mapGetters(['account', 'chainId']),
  },
  props: ['isOpen'],
  data() {
    return {
      showDeposit: false,
      selectedToken: {
        name: this.getDefaultToken(),
        balance: '',
        maxBalance: '',
        input: '',
        allowance: '',
      },
    }
  },
  watch: {
    async isOpen(open, _) {
      if (open) {
        this.showDeposit = false

        let balance = await getBalance(this.selectedToken.name, this.account)
        this.selectedToken.balance = balance
        this.selectedToken.maxBalance = balance
        let tokenAddress = getTokenAddress(this.selectedToken.name)
        this.selectedToken.allowance = await allowance(tokenAddress, this.account)
      }
    },
  },
  methods: {
    getDefaultToken() {
      if (!this.chainId) {
        return 'USDT'
      } else {
        return getTokenList(this.chainId)[0].symbol
      }
    },
    async onDropDownSelect(object) {
      this.selectedToken.input = Number(0)

      this.selectedToken.name = object.name
      let balance = await getBalance(this.selectedToken.name, this.account)
      this.selectedToken.balance = balance
      this.selectedToken.maxBalance = balance
      let tokenAddress = getTokenAddress(this.selectedToken.name)

      let allowance = await allowance(tokenAddress, this.account)
      this.selectedToken.allowance = allowance
      this.selectedToken.maxBalance = allowance
    },
    onClose() {
      this.$emit('close')
    },
    async onMax() {
      let balance = await getBalance(this.selectedToken.name, this.account)
      if (balance == 0) return
      this.selectedToken.maxBalance = this.$format(balance, 4)
      this.selectedToken.input = this.$format(balance, 4)
      this.selectedToken.balance = this.$format(balance, 4)

      if (balance > this.selectedToken.allowance) {
        this.showDeposit = false
      } else {
        this.showDeposit = true
      }
    },
    async onDeposit() {
      try {
        this.$refs.depositButton.isLoading = true
        // console.log('onDeposit:', 'token:', this.selectedToken.name, 'amount:', this.$refs.depositInput.value)
        await deposit(this.selectedToken.name, this.$refs.depositInput.value)
        // let deadline = 2656860541
        // await onAttemptToApprove(this.$refs.depositInput.value, tokenAddress, deadline, this.account)
        this.$refs.depositButton.isLoading = false
        showToast('success', 'Deposit succeeded')
        let tokenAddress = getTokenAddress(this.selectedToken.name)
        this.$store.dispatch('updateBalance', tokenAddress)
      } catch (error) {
        console.error('deposit error', error)
        this.$refs.depositButton.isLoading = false
      }
    },
    async onApprove() {
      try {
        this.$refs.approveButton.isLoading = true
        let tokenAddress = getTokenAddress(this.selectedToken.name)
        let amount = this.selectedToken.input

        let cfg = getTokenCfg(this.selectedToken.name)
        await approve(tokenAddress, amount, cfg.decimals)
        this.$refs.approveButton.isLoading = false
        this.selectedToken.allowance = await allowance(tokenAddress, this.account, cfg.decimals)
        this.showDeposit = true
      } catch (error) {
        console.error(error)
        this.$refs.approveButton.isLoading = false
      }
    },
    tokens() {
      const list = getTokenList(this.chainId)
        .filter((item) => item.native == null)
        .map((currElement, index) => {
          return {
            name: currElement.symbol,
            id: index,
          }
        })
      console.log('list', list)
      return list
    },
    handleInputChange(event) {
      this.selectedToken.input = event.target.value
      let inputVal = Number(event.target.value)
      // console.log('11111 handleInputChange', inputVal)
      // console.log('allowance  ', this.selectedToken.allowance)
      if (inputVal > this.selectedToken.maxBalance) {
        console.log('over max balance')
        this.selectedToken.input = this.selectedToken.maxBalance
      }
      if (inputVal > this.selectedToken.allowance) {
        this.showDeposit = false
      } else {
        this.showDeposit = true
      }
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>

import { ethers } from 'ethers'
import { getNFTAddress } from '../config'
import UseWallet from '../utils/wallet'
import nft from '../abi/NFT.json'

import { mintNFTJoyId } from "../utils/joyid"

async function mint(account) {
    const { walletGlobal } = UseWallet()
    if (walletGlobal.wallet == "joy.id") {
        await mintNFTJoyId(getNFTAddress(), account)
    } else {
        let signer = walletGlobal.signer
        const nftContract = new ethers.Contract(getNFTAddress(), nft, signer)
        await nftContract.mint()
    }
}

async function getOwnedTokens(account) {
    const { walletGlobal } = UseWallet()
    let signer = walletGlobal.signer
    const nftContract = new ethers.Contract(getNFTAddress(), nft, signer)
    let nfts = await nftContract.getOwnedTokens(account)
    return nfts
}


export { mint, getOwnedTokens }
